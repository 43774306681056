import React, {useContext, useEffect, useState} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';
import {
    fetchCompanyDetails, fetchHCAll
} from '../HeadCountAPI/head_count_api';
import { HC_API } from "../config";

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//import Components
import SearchOption from '../Components/Common/SearchOption';
import LanguageDropdown from '../Components/Common/LanguageDropdown';
import WebAppsDropdown from '../Components/Common/WebAppsDropdown';
import MyCartDropdown from '../Components/Common/MyCartDropdown';
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import LightDark from '../Components/Common/LightDark';

import { changeSidebarVisibility } from '../slices/thunks';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import {ViewDetailContext} from "../App";


const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {

    const [companyData, setCompanyData] = useState();

    const [showRefreshBtn, setShowRefreshBtn] = useState(false);

    const [showAppsDropdownBtn, setShowAppsDropdownBtn] = useState(false);

    const {
        setOrgs,
        setTotalOrgHeadCount,
        setErtMembersPresent,
        setTotalErtMembersAvailable
    } = useContext(ViewDetailContext);

    const { type, id } = useParams();


    const changeAlpha = (rgba, newAlpha) => {
        const rgbaParts = rgba.match(/rgba?\(\s*([\d.]+)\s*,\s*([\d.]+)\s*,\s*([\d.]+)\s*,?\s*([\d.]+)?\s*\)/);

        if (!rgbaParts) {
            console.error('Invalid RGBA color format');
            return rgba;
        }
        const [_, r, g, b] = rgbaParts; // Ignore the full match string
        return `rgba(${r}, ${g}, ${b}, ${newAlpha})`;
    };

    const updateThemeVeryLightColor = (color) => {
        const newAlpha = 0.05;
        const newColor = changeAlpha(color, newAlpha);
        document.documentElement.style.setProperty('--headcount-theme-color-very-light', newColor);
          // document.documentElement.style.setProperty('--scrollable-table-header-color-light', '--scrollable-table-header-color');
    };

    const updateButtonColors = (color) => {
        const newAlpha = 0.9;
        const newColor = changeAlpha(color, newAlpha);
        document.documentElement.style.setProperty('--headcount-btn-color', newColor);
        document.documentElement.style.setProperty('--headcount-btn-hover-color', color);
       
    };

    const fetchCompanyData = async () => {
        try {
            document.body.style.opacity = '0';

            const companyDataResponse = await fetchCompanyDetails();
            setCompanyData(companyDataResponse);

            const themeColor = companyDataResponse.color || '#01A0B3';
            document.documentElement.style.setProperty('--headcount-theme-color', themeColor);
            updateThemeVeryLightColor(themeColor);
            updateButtonColors(themeColor);
            // console.log('Company Data : ', themeColor);
            document.body.style.opacity = '1';

        } catch (error) {
            // console.log('Error fetching company details:', error);
        }
    };



    useEffect(() => {
        fetchCompanyData();

    }, []);

    const dispatch = useDispatch();

    const location = useLocation();


    // useEffect(()=>{
    //     // console.log("Location: ", location);
    //     if(location.pathname.indexOf("view-detail") !== -1){
    //         setShowRefreshBtn(true);
    //         setShowAppsDropdownBtn(true);
    //     } else {
    //         setShowRefreshBtn(false);
    //         setShowAppsDropdownBtn(false);
    //     }
    // }, [location]);

    useEffect(() => {
        // Check if the current route matches /view-detail/:id/:type
        const isDetailView = location.pathname.match(/^\/view-detail\/\d+\/\w+/);
        setShowRefreshBtn(isDetailView);
        setShowAppsDropdownBtn(isDetailView);
    }, [location.pathname]);


    const selectDashboardData = createSelector(
        (state) => state.Layout,
        (state) => ({
            sidebarVisibilitytype: state.sidebarVisibilitytype})
      );
    // Inside your component
    const {sidebarVisibilitytype} = useSelector(selectDashboardData);


    const [search, setSearch] = useState(false);
    const toogleSearch = () => {
        setSearch(!search);
    };

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        dispatch(changeSidebarVisibility("show"));

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }


        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };

    const reachToHome = () => {
        window.location.href = '/';
    };

    const hardRefreshAPICall = async () => {
        try {
            await fetchHCAll(id, type, 1);
            window.location.reload();
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };

    const refresh = async () => {
        console.log("Refreshed Successfully");
        const array = window.location.href.split("/");
        const templateId = array[4];
        const type = array[5];

        console.log("Template id: ", templateId);
        console.log("type: ", type);
        hardRefreshAPICall();
    };

    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header position-relative">
                        <div className="d-flex">

                            {/*<div className="navbar-brand-box horizontal-logo">*/}
                            {/*    <Link to="/" className="logo logo-dark">*/}
                            {/*        <span className="logo-sm">*/}
                            {/*            <img src={logoSm} alt="" height="22" />*/}
                            {/*        </span>*/}
                            {/*        <span className="logo-lg">*/}
                            {/*            <img src={logoDark} alt="" height="80" />*/}
                            {/*        </span>*/}
                            {/*    </Link>*/}

                            {/*    <Link to="/" className="logo logo-light">*/}
                            {/*        <span className="logo-sm">*/}
                            {/*            <img src={logoSm} alt="" height="22" />*/}
                            {/*        </span>*/}
                            {/*        <span className="logo-lg">*/}
                            {/*            <img src={logoLight} alt="" height="80" />*/}
                            {/*        </span>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}

                             <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                  <span className="logo-sm">
                                    <img src={companyData?.logo || logoSm} alt="logo" height="55" />
                                  </span>
                                  <span className="logo-lg">
                                    <img src={companyData?.logo || logoDark} alt="logo" height={companyData?.logo_height || 80} />
                                  </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                  <span className="logo-sm">
                                    <img src={companyData?.logo_dark || logoSm} alt="logo" height="55" />
                                  </span>
                                  <span className="logo-lg">
                                    <img src={companyData?.logo_dark || logoLight} alt="logo" height={companyData?.logo_height || 80} />
                                  </span>
                                </Link>
                              </div>

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger d-none"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>


                            {/*<SearchOption />*/}

                        </div>

                        <div className="companyNameHeader">
                            {companyData ? (
                                <h2 className="mb-0">{companyData.name}</h2>
                                ) : (
                                <h2 className="mb-0">BlueEra Softech test</h2>
                            )}
                        </div>

                        <div className="d-flex align-items-center">

                            {/* <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className="bx bx-search fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..."
                                                    aria-label="Recipient's username" />
                                                <button className="btn btn-primary" type="submit">
                                                    <i className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown> */}

                            {/* LanguageDropdown */}
                            {/*<LanguageDropdown />*/}



                            {/* MyCartDropdwon */}
                            {/*<MyCartDropdown />*/}

                            {showRefreshBtn ?  <div className="ms-4 header-item d-none d-sm-flex">
                                <button type="button" onClick={refresh}
                                        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className='bx bx-refresh fs-36'></i>
                                </button>
                            </div> : null }


                            <div className="ms-4 header-item d-none d-sm-flex">
                                <button type="button" onClick={reachToHome}
                                        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                        <i className='bx bx-home-alt fs-36'></i>
                                </button>
                            </div>






                            {/* FullScreenDropdown */}
                            <FullScreenDropdown />

                            {/* Dark/Light Mode set */}
                            <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />

                            {/* WebAppsDropdown */}

                            {/*{showAppsDropdownBtn ? <WebAppsDropdown /> : null }*/}

                            {showAppsDropdownBtn && <WebAppsDropdown />}


                            {/* NotificationDropdown */}
                            {/*<NotificationDropdown />*/}

                            {/* ProfileDropdown */}
                            {/*<ProfileDropdown />*/}
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
