import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Table, Input, InputGroup, InputGroupText } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import AOS from 'aos';
import { ViewDetailContext } from '../../App'; // Adjust import based on your actual context file path
import { fetchHCAll, fetchHCLogs, fetchHCORGHeadCountData, fetchCustomBlock, fetchHCCATHeadCountData, fetchHCDeptHeadCountData, fetchHCHeadCountData, fetchHCBlock1, fetchHCBlock2, fetchHCBlock3 } from '../../HeadCountAPI/head_count_api';
import { HC_API } from '../../config';
import SearchOption from '../../Components/Common/SearchOption';
import '../../assets/css/hc_custom.css';
import Pagination from '../../Components/Common/Pagination';


import { array } from 'yup';
import { jobCandidates } from '../../common/data/appsJobs';


const moment = require('moment'); // For setting Time & Date format


const ViewDetail = () => {
    const { type, id } = useParams();
    const location = useLocation();
    const {
        data,
        setData,
        block1TeamData,
        setBlock1TeamData,
        block2TeamData,
        setBlock2TeamData,
        block3TeamData,
        setBlock3TeamData,
        isBlock1TeamEnabled,
        setIsBlock1TeamEnabled,
        isBlock2TeamEnabled,
        setIsBlock2TeamEnabled,
        isBlock3TeamEnabled,
        setIsBlock3TeamEnabled,
        trueCount,
        setTrueCount,

        orgs,
        setOrgs,
        totalOrgHeadCount,
        setTotalOrgHeadCount,
        showChildTable, setShowChildTable,

        cats,
        setCats,
        totalCatHeadCount,
        setTotalCatHeadCount,

        headcountIn,
        setHeadcountIn,
        headcountOut,
        setHeadcountOut,

        depts,
        setDepts,
        totalDeptHeadCount,
        setTotalDeptHeadCount,

        lastApiResponseTime, setLastApiResponseTime,
        rawShiftNames, setRawShiftNames,
        shiftCounts, setShiftCounts,
        logsData, setLogsData,
        HCLogsData, setHCLogsData,

        customBlock1Label, setCustomBlock1Label,
        customBlock2Label, setCustomBlock2Label,
        customBlock1InCount, setCustomBlock1InCount,
        customBlock2InCount, setCustomBlock2InCount,
        customBlock1TeamData, setCustomBlock1TeamData,
        customBlock2TeamData, setCustomBlock2TeamData,

        customBlock1Image, setCustomBlock1Image,
        customBlock2Image, setCustomBlock2Image,
        inCountWithVisitor, setIn_count_with_visitor

    } = useContext(ViewDetailContext);

    // const dummy_headcountlogs_data = [
    //     {
    //         BranchName: "Vadodara",
    //         CTGName: "Default",
    //         CustomGroup1Name: "-",
    //         CustomGroup2Name: "-",
    //         CustomGroup3Name: "-",
    //         DepartmentName: "Development",
    //         DesignationName: "Sr. Developer",
    //         FullName: "Lyric Burch",
    //         ORGName: "BlueEra Softech",
    //         OfficeMobile: "",
    //         OfficePhone: "",
    //         PersMobile: "",
    //         PersPhone: "",
    //         SectionName: "Section-1",
    //         UFieldValue1: "",
    //         Username: "Lyric Burch",
    //         active: true,
    //         force_full_out_time: null,
    //         id: 903,
    //         in_time: "2024-03-02T15:55:39+05:30",
    //         is_block_1_team: true,
    //         is_block_2_team: false,
    //         is_block_3_team: false,
    //         is_in: true,
    //         mx_user_id: "100011",
    //         out_time: null,
    //         reasons: null
    //     },
    //     {
    //         BranchName: "Vadodara",
    //         CTGName: "Default",
    //         CustomGroup1Name: "-",
    //         CustomGroup2Name: "-",
    //         CustomGroup3Name: "-",
    //         DepartmentName: "Development",
    //         DesignationName: "Sr. Manager",
    //         FullName: "Noa Byrd",
    //         ORGName: "BlueEra Softech",
    //         OfficeMobile: "",
    //         OfficePhone: "",
    //         PersMobile: "",
    //         PersPhone: "",
    //         SectionName: "Section-1",
    //         UFieldValue1: "",
    //         Username: "Noa Byrd",
    //         active: true,
    //         force_full_out_time: null,
    //         id: 904,
    //         in_time: "2024-03-02T15:55:39+05:30",
    //         is_block_1_team: true,
    //         is_block_2_team: false,
    //         is_block_3_team: false,
    //         is_in: true,
    //         mx_user_id: "100012",
    //         out_time: null,
    //         reasons: null
    //     }
    // ];
    const [currentPage, setCurrentPage] = useState(1);
    const [apiSearch, setApiSearch] = useState("")

    // Headcount logs data
    const [apiFilteredData, setApiFilteredData] = useState(data?.headcount_logs)

    // const [apiFilteredData, setApiFilteredData] = useState(dummy_headcountlogs_data)
    const [headCountLogsApiFirstCalled, setHeadCountLogsApiFirstCalled] = useState(true)

    const handleSearch = async (event) => {
        setApiSearch(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {

            try {
                //   const response = await fetch(`https://headcountbackend.blueerasoftech.com/api/hc_templates/headcount-logs/search?q=${apiSearch}`);
                if (type === "headcountlogs") {
                    console.log("Calling Fetch HCLogs data from apiSearch UseEffect")
                    const response = await fetchHCLogs(id, type, 0, perPageData, indexOfFirst, apiSearch)
                    const data = await response.logsData;
                    setApiFilteredData(data);

                    // setApiFilteredData(dummy_headcountlogs_data)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

        };

        if (!headCountLogsApiFirstCalled) {
            console.log("Before fetching hc data")
            fetchData();
        }
        else {
            console.log("Headcount logs API is not called first")
        }

        setHeadCountLogsApiFirstCalled(false);
    }, [apiSearch]);

    //pagination
    const perPageData = 10;
    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;

    const BASE_HEIGHT = 900;
    const BASE_WIDTH = 1025
    const [scaleY, setScaleY] = useState(1);
    const [scaleX, setScaleX] = useState(1);

    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    const updateScale = () => {
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        console.log("Viewport Width: ", viewportWidth)
        console.log("Viewport Height:", viewportHeight);

        // If viewport height is less than BASE_HEIGHT, use a fixed scale of 0.8 (80%)
        if (viewportHeight < BASE_HEIGHT) {
            console.log("Viewport is below base height.");
            setScaleY(0.8);


        } else {
            console.log("No scaling needed. Using scaleY: 1");
            setScaleY(1);
        }
    };

    useEffect(() => {
        updateScale();
        window.addEventListener('resize', updateScale);
        return () => window.removeEventListener('resize', updateScale);
    }, []);



    // const block1TeamDataDummy = [
    //     {
    //         data: [
    //             { id: 449, mx_user_id: 5241, Username: 'Shankar Ray', FullName: 'Shankar Ray', ORGName: 'BlueEra Softech' },
    //             { id: 450, mx_user_id: 5762, Username: 'Mohan Bhagat', FullName: 'Mohan Bhagat', ORGName: 'BlueEra Softech' },
    //             { id: 451, mx_user_id: 92, Username: 'Puneet Kumar', FullName: 'Puneet Kumar', ORGName: 'BlueEra Softech' },


    //         ],
    //         name: "ERT",
    //         phoneField: "OfficeMobile",
    //         total: 3
    //     }
    // ];

    const enabledTeamsDummy2 = [
        {
            "name": "ERT",
            "phoneField": "OfficeMobile",
            "total": 1,
            "data": [
                {
                    "id": 449,
                    "mx_user_id": 5241,
                    "Username": "Shankar Ray",
                    "FullName": "Shankar Ray",
                    "ORGName": "BlueEra Softech",
                    "OfficeMobile": "9978834345"
                },

            ]
        },
        {
            "name": "Fire Marshals",
            "phoneField": "OfficeMobile",
            "total": 1,
            "data": [
                {
                    "id": 454,
                    "mx_user_id": 6241,
                    "Username": "Rajesh Kumar",
                    "FullName": "Rajesh Kumar",
                    "ORGName": "BlueEra Softech",
                    "OfficeMobile": "9980011122"
                },


            ]
        },
        {
            "name": "First Aid",
            "phoneField": "OfficeMobile",
            "total": 1,
            "data": [
                {
                    "id": 459,
                    "mx_user_id": 7241,
                    "Username": "Sonal Mehta",
                    "FullName": "Sonal Mehta",
                    "ORGName": "BlueEra Softech",
                    "BranchName": "Vadodara",
                    "DepartmentName": "Production",
                    "DesignationName": "Manager",
                    "OfficeMobile": "9990011122",
                    "in_time": "2024-07-05T14:23:38+05:30",
                    "working_shift": "GS"
                },



            ]
        }
    ]



    const handleRowClick = () => {
        setTimeout(() => {
            window.open(`/view-detail/1/headcountlogs`);

        }, 100); // 100 milliseconds delay
    };

    //Code for type = 'all'
    const fetchAll = async () => {
        try {
            const { data,
                block1TeamData,
                block2TeamData,
                block3TeamData,
                trueCount,
                is_block_1_team_enabled,
                is_block_2_team_enabled,
                is_block_3_team_enabled,
                dataAppended } = await fetchHCAll(id, type, 0);

            console.log("After Getting Data from HCAll");



            setLastApiResponseTime(new Date());

            if (dataAppended) {

                setData(data);
                setBlock1TeamData(block1TeamData);
                setBlock2TeamData(block2TeamData);
                setBlock3TeamData(block3TeamData);
                setIsBlock1TeamEnabled(is_block_1_team_enabled);
                setIsBlock2TeamEnabled(is_block_2_team_enabled);
                setIsBlock3TeamEnabled(is_block_3_team_enabled);
                setTrueCount(trueCount);
            }

        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };



    const enabledTeams = [
        isBlock1TeamEnabled ? { data: block1TeamData, total: data?.in_block_1_count, phoneField: data?.block_1_phone_field, name: data?.block_1_label } : null,
        isBlock2TeamEnabled ? { data: block2TeamData, total: data?.in_block_2_count, phoneField: data?.block_2_phone_field, name: data?.block_2_label } : null,
        isBlock3TeamEnabled ? { data: block3TeamData, total: data?.in_block_3_count, phoneField: data?.block_3_phone_field, name: data?.block_3_label } : null,
    ].filter(Boolean); // Filter out null values

    const shouldApplyStyle = enabledTeams.some(team => team?.total > 6);



    // const trueCount_birthdayDummy = enabledTeamsDummy1.length; 

    // const birthdayTeamCount = data.birthday_teams_2 && Array.isArray(data.birthday_teams_2) && data.birthday_teams_2.length
    const [membersPerRow, setMembersPerRow] = useState(getMembersPerRow());
    useEffect(() => {
        const handleResize = () => {
            setMembersPerRow(getMembersPerRow());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function getMembersPerRow() {
        const width = window.innerWidth;
        if (width < 768) return 1;
        return 3;
    }
    const getShiftCount = (org, shift) => {

        if (data?.shifts !== undefined || data?.shifts.length !== 0) {

            const countKey = `${shift.toLowerCase()}_count`;
            return org[countKey] || 0; // Default to 0 if not found
        }
    };

    let totalContactorCount1 = 0
    let totalContactorCount2 = 0
    let totalContactorCount3 = 0
    let totalOthersCount = 0



    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        console.log('Team length greater than 4:', shouldApplyStyle);
    }, [shouldApplyStyle]);
    // console.log("Team length greater than 4 : ", shouldApplyStyle);

    const countsAPIKeysName = []

    //Code for type = 'organization'
    const fetchOrgHeadCountData = async () => {


        try {

            const { orgs, totalOrgHeadCount, data, dataAppended } = await fetchHCORGHeadCountData(id, type, 0);
            if (dataAppended) {

                // console.log("ORG API DATA", orgs)

                // const orgWiseData = [
                //     {
                //       ORGName: "BlueEra Softech",
                //       count: 360,
                //       gs_count: 260,
                //       mu_count: 50,
                //       others_count: 50,
                //       ws_count: 0,
                //     },
                //       {
                //         ORGName: "Contractor A",
                //         count: 61,
                //         gs_count: 20,
                //         mu_count: 0,
                //         others_count: 31,
                //         ws_count: 10,
                //       },
                //       {
                //         ORGName: "Contractor B",
                //         count: 46,
                //         gs_count: 12,
                //         mu_count: 10,
                //         others_count: 14,
                //         ws_count: 10,
                //       },
                //       {
                //         ORGName: "Visitors",
                //         count: 16,
                //       },
                //   ];


                setOrgs(orgs);
                setTotalOrgHeadCount(totalOrgHeadCount);
                setData(data);
                setLastApiResponseTime(new Date());
            }


        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };

    const childRows = orgs.filter(item => item.ORGName !== data.organization_name && item.ORGName !== 'Visitors') || [];

    data.orgs !== undefined ? childRows.forEach(item => {
        rawShiftNames !== undefined ? totalContactorCount1 = totalContactorCount1 + getShiftCount(item, rawShiftNames[0]) : null
        rawShiftNames !== undefined ? totalContactorCount2 = totalContactorCount2 + getShiftCount(item, rawShiftNames[1]) : null
        rawShiftNames !== undefined ? totalContactorCount3 = totalContactorCount3 + getShiftCount(item, rawShiftNames[2]) : null
        totalOthersCount = totalOthersCount + item.others_count

    }) : null;
    // const mainRows = orgs.filter(item => item.ORGName === data.organization_name || item.ORGName === 'Visitors');
    const mainRows = orgs.filter(item =>
        item.ORGName === data.organization_name ||
        (item.ORGName === 'Visitors' && data.is_vms_enabled)
    ) || [];

    let totalContractorCount = 0

    function addTotalCount(totalContractorCount, data) {
        // Iterate through the data array using a for loop
        for (let i = 0; i < data.length; i++) {
            const item = data[i]; // Get the current item at index i
            console.log("total addCount", totalContractorCount);
            totalContractorCount += item.count; // Add item.count to totalContractorCount
        }
        // Return the updated totalContractorCount
        return totalContractorCount;
    }

    const toggleChildTable = () => {
        setShowChildTable(!showChildTable);
    };

    //Code for type = 'category'
    const fetchCatHeadCountData = async () => {
        try {
            const { cats, totalCatHeadCount, data, dataAppended } = await fetchHCCATHeadCountData(id, type, 0);

            console.log("CATS ", cats)

            // const categoryWiseData = [
            //     {
            //       CTGName: "Employees",
            //       count: 360,
            //       gs_count: 260,
            //       mu_count: 50,
            //       others_count: 50,
            //       ws_count: 0,
            //     },
            //     {
            //       CTGName: "Workers",
            //       count: 107,
            //       gs_count: 82,
            //       mu_count: 20,
            //       others_count: 4,
            //       ws_count: 1,
            //     },

            //     {
            //       CTGName: "Visitors",
            //       count: 16,
            //     },
            //   ];


            if (dataAppended) {
                setCats(cats);
                setData(data);
                setTotalCatHeadCount(totalCatHeadCount);
                setLastApiResponseTime(new Date());

            }
            else {
                console.log("Data appended in cat", dataAppended)
            }
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };

    //Code for type = 'headcount_Logs'
    const fetchHCLogsData = async () => {

        try {

            console.log("Headcount Logs API Fired from HCLogsData Function")

            let response;
            if (type === "headcountlogs") {
                response = await fetchHCLogs(id, type, 0, perPageData, indexOfFirst, apiSearch);
            }
            // Manually assign values from response
            const logsDataApi = response.logsData;
            const data = response.data;

            setLogsData(logsDataApi)

            type === "headcountlogs" && setData(data)
            setHCLogsData(data)
            setLastApiResponseTime(new Date());
            setApiFilteredData(logsDataApi);

        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };


    const fetchCustomBlockData = async () => {

        try {

            const { data, dataAppended } = await fetchCustomBlock(id, type, 0);


            if (dataAppended) {
                console.log("Value of Data appended from if block ", dataAppended)
                setData(data)
                if (type === "custom_2") {

                    setCustomBlock2TeamData(data?.block_2_team)
                    setCustomBlock2InCount(data?.in_block_2_count)
                    setCustomBlock2Label(data?.block_2_label)
                    setCustomBlock2Image(data?.block_2_image)

                }

                else if (type === "custom_1") {

                    if (dataAppended) {

                        console.log("VALUE OF DATA APPENDED CUSTOM 1 FND", dataAppended)
                        setData(data)

                        setCustomBlock1Image(data?.block_1_image)
                        setCustomBlock1Label(data?.block_1_label)
                        setCustomBlock1InCount(data?.in_block_1_count)
                        setCustomBlock1TeamData(data?.block_1_team)
                    }

                    else {
                        console.log("Data appended value in CB1", dataAppended)
                    }
                }
                setLastApiResponseTime(new Date());
            }
            else {
                console.log("Value of Data Appended is", dataAppended)
            }
        } catch (error) {
            console.error('Error fetching custom block 1 Data:', error);
        }
    };


    useEffect(() => {
        console.log("customBlock1Label:", customBlock1Label);
        console.log("customBlock2Label:", customBlock2Label);
        console.log("customBlock1InCount:", customBlock1InCount);
        console.log("customBlock2InCount:", customBlock2InCount);
        console.log("customBlock1TeamData:", customBlock1TeamData);
        console.log("customBlock2TeamData:", customBlock2TeamData);
        console.log("customBlock1Image (URL):", customBlock1Image);
        console.log("customBlock2Image (URL):", customBlock2Image);
    }, [
        customBlock1Label,
        customBlock2Label,
        customBlock1InCount,
        customBlock2InCount,
        customBlock1TeamData,
        customBlock2TeamData,
        customBlock1Image,
        customBlock2Image
    ]);

    // const block1DummyData = [
    //     {
    //       "id": 449,
    //       "FullName": "Shankar Ray",
    //       "Username": "Shankar Ray",
    //       "BirthDate": "08/12/1954",
    //       "ORGName": "BlueEra Softech",
    //       "BranchName": "Vadodara",
    //       "SectionName": "Vadodara",
    //       "DepartmentName": "Production",
    //       "DesignationName": "Manager",
    //       "CTGName": "Default",
    //       "CustomGroup1Name": "Custom Group 1",
    //       "CustomGroup2Name": "Custom Group 2",
    //       "CustomGroup3Name": "Custom Group 3",
    //       "OfficeMobile": "9978834345",
    //       "OfficePhone": null,
    //       "PersMobile": "9978834345",
    //       "PersPhone": null,
    //       "UFieldValue1": "",
    //       "active": true,
    //       "is_emergency_team": true,
    //       "is_in": true,
    //       "force_full_out_time": null,
    //       "in_time": "2024-07-05T14:23:38+05:30",
    //       "out_time": null,
    //       "reasons": null,
    //       "working_shift": "GS",
    //       "mx_user_id": 5241
    //     },
    //     {
    //       "id": 450,
    //       "FullName": "Mohan Bhagat",
    //       "Username": "Mohan Bhagat",
    //       "BirthDate": "08/12/1954",
    //       "ORGName": "BlueEra Softech",
    //       "BranchName": "Vadodara",
    //       "SectionName": "Vadodara",
    //       "DepartmentName": "QC",
    //       "DesignationName": "Manager",
    //       "CTGName": "Default",
    //       "CustomGroup1Name": "Custom Group 1",
    //       "CustomGroup2Name": "Custom Group 2",
    //       "CustomGroup3Name": "Custom Group 3",
    //       "OfficeMobile": "9978824247",
    //       "OfficePhone": null,
    //       "PersMobile": "9978824247",
    //       "PersPhone": null,
    //       "UFieldValue1": "",
    //       "active": true,
    //       "is_emergency_team": true,
    //       "is_in": true,
    //       "force_full_out_time": null,
    //       "in_time": "2024-07-05T14:24:55+05:30",
    //       "out_time": null,
    //       "reasons": null,
    //       "working_shift": "GS",
    //       "mx_user_id": 5762
    //     },
    //     {
    //       "id": 451,
    //       "FullName": "Puneet Kumar",
    //       "Username": "Puneet Kumar",
    //       "BirthDate": "08/12/1954",
    //       "ORGName": "BlueEra Softech",
    //       "BranchName": "Vadodara",
    //       "SectionName": "Vadodara",
    //       "DepartmentName": "Production",
    //       "DesignationName": "Manager",
    //       "CTGName": "Default",
    //       "CustomGroup1Name": "Custom Group 1",
    //       "CustomGroup2Name": "Custom Group 2",
    //       "CustomGroup3Name": "Custom Group 3",
    //       "OfficeMobile": "9978824247",
    //       "OfficePhone": null,
    //       "PersMobile": "9978824247",
    //       "PersPhone": null,
    //       "UFieldValue1": "",
    //       "active": true,
    //       "is_emergency_team": true,
    //       "is_in": true,
    //       "force_full_out_time": null,
    //       "in_time": "2024-07-05T14:25:11+05:30",
    //       "out_time": null,
    //       "reasons": null,
    //       "working_shift": "GS",
    //       "mx_user_id": 92
    //     },
    //     {
    //       "id": 452,
    //       "FullName": "Surendra Iyer",
    //       "Username": "Surendra Iyer",
    //       "BirthDate": "08/12/1954",
    //       "ORGName": "BlueEra Softech",
    //       "BranchName": "Vadodara",
    //       "SectionName": "Vadodara",
    //       "DepartmentName": "QC",
    //       "DesignationName": "Manager",
    //       "CTGName": "Default",
    //       "CustomGroup1Name": "Custom Group 1",
    //       "CustomGroup2Name": "Custom Group 2",
    //       "CustomGroup3Name": "Custom Group 3",
    //       "OfficeMobile": "9978834345",
    //       "OfficePhone": "",
    //       "PersMobile": "9978834345",
    //       "PersPhone": "",
    //       "UFieldValue1": "",
    //       "active": true,
    //       "is_emergency_team": true,
    //       "is_in": true,
    //       "force_full_out_time": null,
    //       "in_time": "2024-07-05T14:28:03+05:30",
    //       "out_time": null,
    //       "reasons": null,
    //       "working_shift": "GS",
    //       "mx_user_id": 5354
    //     },
    //     {
    //       "id": 453,
    //       "FullName": "Mamta Thapad",
    //       "Username": "Mamta Thapad",
    //       "BirthDate": "08/12/1954",
    //       "ORGName": "BlueEra Softech",
    //       "BranchName": "Vadodara",
    //       "SectionName": "Vadodara",
    //       "DepartmentName": "Production",
    //       "DesignationName": "Manager",
    //       "CTGName": "Default",
    //       "CustomGroup1Name": "Custom Group 1",
    //       "CustomGroup2Name": "Custom Group 2",
    //       "CustomGroup3Name": "Custom Group 3",
    //       "OfficeMobile": "9978824247",
    //       "OfficePhone": "",
    //       "PersMobile": "9978824247",
    //       "PersPhone": "",
    //       "UFieldValue1": "",
    //       "active": true,
    //       "is_emergency_team": true,
    //       "is_in": true,
    //       "force_full_out_time": null,
    //       "in_time": "2024-07-05T14:28:38+05:30",
    //       "out_time": null,
    //       "reasons": null,
    //       "working_shift": "GS",
    //       "mx_user_id": 2363
    //     },
    //     {
    //       "id": 452,
    //       "FullName": "Bhushan Patel",
    //       "Username": "Bhushan Patel",
    //       "BirthDate": "08/12/1954",
    //       "ORGName": "BlueEra Softech",
    //       "BranchName": "Vadodara",
    //       "SectionName": "Vadodara",
    //       "DepartmentName": "QC",
    //       "DesignationName": "Manager",
    //       "CTGName": "Default",
    //       "CustomGroup1Name": "Custom Group 1",
    //       "CustomGroup2Name": "Custom Group 2",
    //       "CustomGroup3Name": "Custom Group 3",
    //       "OfficeMobile": "9978834345",
    //       "OfficePhone": "",
    //       "PersMobile": "9978834345",
    //       "PersPhone": "",
    //       "UFieldValue1": "",
    //       "active": true,
    //       "is_emergency_team": true,
    //       "is_in": false,
    //       "force_full_out_time": null,
    //       "in_time": "2024-07-05T14:28:03+05:30",
    //       "out_time": "2024-07-05T20:28:03+05:30",
    //       "reasons": null,
    //       "working_shift": "GS",
    //       "mx_user_id": 5354
    //     },
    //     {
    //       "id": 453,
    //       "FullName": "Ashish Panchal",
    //       "Username": "Ashish Panchal",
    //       "BirthDate": "08/12/1954",
    //       "ORGName": "BlueEra Softech",
    //       "BranchName": "Vadodara",
    //       "SectionName": "Vadodara",
    //       "DepartmentName": "Production",
    //       "DesignationName": "Manager",
    //       "CTGName": "Default",
    //       "CustomGroup1Name": "Custom Group 1",
    //       "CustomGroup2Name": "Custom Group 2",
    //       "CustomGroup3Name": "Custom Group 3",
    //       "OfficeMobile": "9978824247",
    //       "OfficePhone": "",
    //       "PersMobile": "9978824247",
    //       "PersPhone": "",
    //       "UFieldValue1": "",
    //       "active": true,
    //       "is_emergency_team": true,
    //       "is_in": false,
    //       "force_full_out_time": null,
    //       "in_time": "2024-07-05T14:28:38+05:30",
    //       "out_time": "2024-07-05T21:28:38+05:30",
    //       "reasons": null,
    //       "working_shift": "GS",
    //       "mx_user_id": 2363
    //     }
    //   ]

    const filteredCats = cats.filter(item => item.CTGName !== 'Visitors' || data.is_vms_enabled);

    //Code for type = 'department'
    const fetchDeptHeadCountData = async () => {
        try {
            const { depts, totalDeptHeadCount, data, dataAppended } = await fetchHCDeptHeadCountData(id, type, 0);
            if (dataAppended) {

                console.log("DEPARTMENT DATA", depts)

                // const departmentWiseData = [
                //     {
                //       DepartmentName: "Development",
                //       count: 360,
                //       gs_count: 260,
                //       mu_count: 50,
                //       others_count: 50,
                //       ws_count: 0,
                //     },
                //     {
                //       DepartmentName: "Admin",
                //       count: 107,
                //       gs_count: 82,
                //       mu_count: 20,
                //       others_count: 4,
                //       ws_count: 1,
                //     },
                //     {
                //       DepartmentName: "Visitors",
                //       count: 16,
                //     },
                //   ];

                setDepts(depts);
                setData(data);
                setHeadcountIn(data.hc_in_count)
                setHeadcountOut(data.hc_out_count)
                setTotalDeptHeadCount(totalDeptHeadCount);
                setLastApiResponseTime(new Date());
            }
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };
    const filteredDepts = depts.filter(item => item.DepartmentName !== 'Visitors' || data.is_vms_enabled);

    useEffect(() => {
        if (data.hc_in_count && data.hc_out_count) {

            setHeadcountIn(data.hc_in_count)
            setHeadcountOut(data.hc_out_count)
        }
    }, [data.hc_in_count, data.hc_out_count])

    const fetchHeadCountData = async () => {
        try {
            const { data, dataAppended, inCountWithVisitor } = await fetchHCHeadCountData(id, type, 0);
            if (dataAppended) {
                // setIn_count_with_visitor(inCountWithVisitor)

                setData(data);
            }
            setLastApiResponseTime(new Date());
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };

    //Code for type = 'block_1'
    const fetchBlock1Data = async () => {
        try {
            const { data,
                block1TeamData,
                trueCount,
                is_block_1_team_enabled,
                dataAppended
            } = await fetchHCBlock1(id, type, 0);

            if (dataAppended) {

                setData(data);
                setBlock1TeamData(block1TeamData);
                setIsBlock1TeamEnabled(is_block_1_team_enabled);
                setTrueCount(trueCount);
                setLastApiResponseTime(new Date());
            }
            // console.log("block1TeamData : ",block1TeamData);
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };
    const enabledBlock1Teams = [
        isBlock1TeamEnabled ? { data: block1TeamData, total: data?.in_block_1_count, phoneField: data?.block_1_phone_field, name: data?.block_1_label } : null,
    ].filter(Boolean);

    const shouldApplyStyleBlock1 = enabledBlock1Teams.some(team => team?.total > 6);

    //Code for type = 'block_2'
    const fetchBlock2Data = async () => {
        try {
            const { data,
                block2TeamData,
                trueCount,
                is_block_2_team_enabled,
                dataAppended
            } = await fetchHCBlock2(id, type, 0);

            if (dataAppended) {

                setData(data);
                setBlock2TeamData(block2TeamData);
                setIsBlock2TeamEnabled(is_block_2_team_enabled);
                setTrueCount(trueCount);
                setLastApiResponseTime(new Date());
            }
            // console.log("block2TeamData : ",block2TeamData);
        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };
    const enabledBlock2Teams = [
        isBlock2TeamEnabled ? { data: block2TeamData, total: data?.in_block_2_count, phoneField: data?.block_2_phone_field, name: data?.block_2_label } : null,
    ].filter(Boolean);

    // const block2DummyData = [
    //     {
    //         name: "Fire Marshals",
    //         phoneField: "OfficeMobile",
    //         total: 5,
    //         data: [
    //             {
    //                 id: 449,
    //                 mx_user_id: 5241,
    //                 Username: "Shankar Ray",
    //                 FullName: "Shankar Ray",
    //                 ORGName: "BlueEra Softech",
    //                 OfficeMobile: "9978834345",
    //                 OfficePhone: null,
    //                 PersMobile: "9978834345",
    //                 PersPhone: null,
    //                 UFieldValue1: "",
    //                 active: true,
    //                 force_full_out_time: null,
    //                 in_time: "2024-07-05T14:23:38+05:30",
    //                 out_time: null,
    //                 is_in: true,
    //                 is_block_1_team: true,
    //                 reasons: null,
    //             },
    //             {
    //                 id: 450,
    //                 mx_user_id: 5762,
    //                 Username: "Mohan Bhagat",
    //                 FullName: "Mohan Bhagat",
    //                 ORGName: "BlueEra Softech",
    //                 OfficeMobile: "9978824247",
    //                 OfficePhone: null,
    //                 PersMobile: "9978824247",
    //                 PersPhone: null,
    //                 UFieldValue1: "",
    //                 active: true,
    //                 force_full_out_time: null,
    //                 in_time: "2024-07-05T14:24:55+05:30",
    //                 out_time: null,
    //                 is_in: true,
    //                 is_block_1_team: true,
    //                 reasons: null,
    //             },
    //             {
    //                 id: 451,
    //                 mx_user_id: 92,
    //                 Username: "Puneet Kumar",
    //                 FullName: "Puneet Kumar",
    //                 ORGName: "BlueEra Softech",
    //                 OfficeMobile: "9978824247",
    //                 OfficePhone: null,
    //                 PersMobile: "9978824247",
    //                 PersPhone: null,
    //                 UFieldValue1: "",
    //                 active: true,
    //                 force_full_out_time: null,
    //                 in_time: "2024-07-05T14:25:11+05:30",
    //                 out_time: null,
    //                 is_in: true,
    //                 is_block_1_team: true,
    //                 reasons: null,
    //             },
    //             {
    //                 id: 452,
    //                 mx_user_id: 5354,
    //                 Username: "Surendra Iyer",
    //                 FullName: "Surendra Iyer",
    //                 ORGName: "BlueEra Softech",
    //                 OfficeMobile: "9978834345",
    //                 OfficePhone: "",
    //                 PersMobile: "9978834345",
    //                 PersPhone: "",
    //                 UFieldValue1: "",
    //                 active: true,
    //                 force_full_out_time: null,
    //                 in_time: "2024-07-05T14:28:03+05:30",
    //                 out_time: null,
    //                 is_in: true,
    //                 is_block_1_team: true,
    //                 reasons: null,
    //             },
    //             {
    //                 id: 453,
    //                 mx_user_id: 2363,
    //                 Username: "Mamta Thapad",
    //                 FullName: "Mamta Thapad",
    //                 ORGName: "BlueEra Softech",
    //                 OfficeMobile: "9978824247",
    //                 OfficePhone: "",
    //                 PersMobile: "9978824247",
    //                 PersPhone: "",
    //                 UFieldValue1: "",
    //                 active: true,
    //                 force_full_out_time: null,
    //                 in_time: "2024-07-05T14:28:38+05:30",
    //                 out_time: null,
    //                 is_in: true,
    //                 is_block_1_team: true,
    //                 reasons: null,
    //             },
    //         ],
    //     },
    // ];

    const shouldApplyStyleBlock2 = enabledBlock2Teams.some(team => team?.total > 6);

    useEffect(() => {
        data.shifts !== 0 && handleShiftCountCalculation();
        data.shifts !== 0 && setRawShiftNames(data.shifts)

    }, [data.shifts]); // Assuming 'data' is the dependency


    const handleShiftCountCalculation = () => {

        if (data.shifts !== undefined) {

            const newShiftCounts = data.is_display_shift ? (
                data.shifts.map((shift, index) => (
                    {
                        index,
                        shift,
                        [`shiftBlock${index}`]: `${shift.toLowerCase()}_count` || 0,
                        // Add the index property
                    }))
            ) : [];

            setShiftCounts(newShiftCounts);
        }
    };

    //Code for type = 'block_3'
    const fetchBlock3Data = async () => {
        try {

            console.log("Fetching Block3 data ")
            const { data,
                block3TeamData,
                trueCount,
                is_block_3_team_enabled,
                dataAppended
            } = await fetchHCBlock3(id, type, 0);

            if (dataAppended) {

                setData(data);
                setBlock3TeamData(block3TeamData);
                setIsBlock3TeamEnabled(is_block_3_team_enabled);
                setTrueCount(trueCount);
                setLastApiResponseTime(new Date());
            }

        } catch (error) {
            console.error('Error fetching head count Data:', error);
        }
    };
    const enabledBlock3Teams = [
        isBlock3TeamEnabled ? { data: block3TeamData, total: data?.in_block_3_count, phoneField: data?.block_3_phone_field, name: data?.block_3_label } : null,
    ].filter(Boolean);


    // const block3DummyData = [
    //     {
    //       name: "First Aid",
    //       phoneField: "OfficeMobile",
    //       total: 2,
    //       data: [
    //           {
    //             id: 449,
    //             mx_user_id: 5241,
    //             Username: "Shankar Ray",
    //             FullName: "Shankar Ray",
    //             ORGName: "BlueEra Softech",
    //             OfficeMobile: "9978834345",
    //             OfficePhone: null,
    //             PersMobile: "9978834345",
    //             PersPhone: null,
    //             UFieldValue1: "",
    //             active: true,
    //             force_full_out_time: null,
    //             in_time: "2024-07-05T14:23:38+05:30",
    //             out_time: null,
    //             is_in: true,
    //             is_block_1_team: true,
    //             reasons: null,
    //           },
    //           {
    //             id: 450,
    //             mx_user_id: 5762,
    //             Username: "Mohan Bhagat",
    //             FullName: "Mohan Bhagat",
    //             ORGName: "BlueEra Softech",
    //             OfficeMobile: "9978824247",
    //             OfficePhone: null,
    //             PersMobile: "9978824247",
    //             PersPhone: null,
    //             UFieldValue1: "",
    //             active: true,
    //             force_full_out_time: null,
    //             in_time: "2024-07-05T14:24:55+05:30",
    //             out_time: null,
    //             is_in: true,
    //             is_block_1_team: true,
    //             reasons: null,
    //           },

    //       ],
    //     },
    //   ];

    const shouldApplyStyleBlock3 = enabledBlock3Teams.some(team => team?.total > 6);


    useEffect(() => {
        AOS.init({
            easing: 'ease-out-back',
            duration: 3000,
            anchorPlacement: 'top-bottom',
        });
    }, []);

    useEffect(() => {
        let interval;
        if (type === 'all') {
            interval = setInterval(fetchAll, HC_API.POLLING_INTERVAL);
            fetchAll();
        } else if (type === 'organization') {
            interval = setInterval(fetchOrgHeadCountData, HC_API.POLLING_INTERVAL);
            fetchOrgHeadCountData();
        } else if (type === 'category') {
            interval = setInterval(fetchCatHeadCountData, HC_API.POLLING_INTERVAL);
            fetchCatHeadCountData();
        } else if (type === 'department') {
            interval = setInterval(fetchDeptHeadCountData, HC_API.POLLING_INTERVAL);
            fetchDeptHeadCountData();
        } else if (type === 'headcount') {
            interval = setInterval(fetchHeadCountData, HC_API.POLLING_INTERVAL);
            fetchHeadCountData();
        } else if (type === 'block_1') {
            interval = setInterval(fetchBlock1Data, HC_API.POLLING_INTERVAL);
            fetchBlock1Data();
        } else if (type === 'block_2') {
            interval = setInterval(fetchBlock2Data, HC_API.POLLING_INTERVAL);
            fetchBlock2Data();
        }
        else if (type === 'headcountlogs') {
            // interval = setInterval(fetchHCLogsData, HC_API.POLLING_INTERVAL);
            // fetchHCLogsData();
        }
        else if (type === "birthday_teams_2") {

            console.log("Inside Birthdat Teams 2 Block")
            interval = setInterval(fetchAll, HC_API.POLLING_INTERVAL);
            fetchAll();
            console.log("After executing Birthday Team function")
        }

        else if (type === "custom_1") {
            console.log("Inside Custom 1 Block")
            interval = setInterval(fetchCustomBlockData, HC_API.POLLING_INTERVAL);
            fetchCustomBlockData()
            // setData(data);
        }

        else if (type === "custom_2") {
            console.log("Inside Custom 2 Block")
            interval = setInterval(fetchCustomBlockData, HC_API.POLLING_INTERVAL);
            // fetchAll();
            // fetchCustomBlock()
            fetchCustomBlockData()
            // setData(data);
        }

        else {
            interval = setInterval(fetchBlock3Data, HC_API.POLLING_INTERVAL);
            console.log("Fetching Block 3 data")
            fetchBlock3Data();
        }
        return () => clearInterval(interval);
    }, [location, id, type]);




    useEffect(() => {
        if (type === "headcountlogs") {

            console.log("Type is Headcount Logs. So Calling headcount_logs API from current page UseEffect ")

            fetchHCLogsData();
        }
        else {
            console.log("Not fetching the api as HC LOGS Page is not active")
        }
    }, [currentPage]);

    console.log("VALUE OF SHOULDAPPLYSTYLE ", shouldApplyStyle)


    return (
        <React.Fragment>
            <div className="page-content" style={{ marginTop: '35px' }}>
                {/* <div className="page-content" style={{ marginTop: scaleY !== 1 ? '140px' : '65px', marginBottom: scaleY !==1 && '140px'}}> */}

                {type === 'all' && (
                    <Container fluid>

                        {/* Working for Case 1 */}

                        {/* <div
                            style={{
                                marginTop: shouldApplyStyle
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                                marginBottom: shouldApplyStyle
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                            }}> */}


                        <div
                            style={{
                                marginTop: shouldApplyStyle
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "0px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                                marginBottom: shouldApplyStyle
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "30px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                            }}
                        >


                            {/* Working for Case 1  */}
                            {/* <div
                                className="customBlockWrapper"
                                style={{
                                    height: shouldApplyStyle
                                        ? (scaleY === 1 ? "auto" : undefined)  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}> */}

                            <div
                                className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                                style={{
                                    height: shouldApplyStyle
                                        ? (scaleY === 1 ? "auto" : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}>



                                <div className="customBlock w-100" >
                                    <Row>
                                        <Col xs={12} >


                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ padding: '0' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white', textTransform: 'uppercase' }}>
                                                                <tr style={{
                                                                    verticalAlign: 'middle',
                                                                    wordWrap: 'wrap',
                                                                    paddingRight: '15px'
                                                                }}
                                                                    className='tableHeadResponsive'>


                                                                    <th
                                                                        scope="col"
                                                                        className="text-center responsive-th responsive-xs-text bar-table-responsive bar-text"
                                                                        style={{
                                                                            borderBottomWidth: '0',
                                                                            paddingLeft: '15px'
                                                                        }}>
                                                                        Total Head Count
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="bar-count responsive-th responsive-xs-text bar-table-responsive"
                                                                        style={{
                                                                            borderBottomWidth: '0',
                                                                            paddingRight: '15px'
                                                                        }}
                                                                    >
                                                                        {data.in_count_with_visitor}
                                                                    </th>


                                                                </tr>
                                                            </thead>
                                                            {data.is_vms_enabled && (
                                                                <tbody style={{ color: 'var(--headcount-theme-titles-color)', fontWeight: '600' }}>
                                                                    <tr >
                                                                        <td className="text-center mob-table-body responsive-th responsive-xs-text horizontalBlueBar" style={{
                                                                            padding: '15px 15px',
                                                                            // borderRight: '2px solid var(--headcount-theme-color)',
                                                                            position: 'relative',
                                                                        }}>
                                                                            EMPLOYEES
                                                                            <span
                                                                                className="text-center"
                                                                                style={{
                                                                                    marginLeft: '10%',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>{data.in_count}
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-center responsive-th mob-table-body responsive-xs-text" style={{
                                                                            padding: '15px 15px',
                                                                            position: 'relative',
                                                                        }}>
                                                                            VISITORS
                                                                            <span
                                                                                className="text-center"
                                                                                style={{
                                                                                    marginLeft: '10%',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>{data.in_count_visitor}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {enabledTeams && Array.isArray(enabledTeams) && enabledTeams.map((team, index) => (
                                            <Col
                                                key={index}
                                                lg={trueCount === 1 ? 12 : trueCount === 2 ? 6 : trueCount === 3 ? 4 : 12}>
                                                <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                    <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                        <div className="table-responsive">
                                                            <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                                <thead>
                                                                    <tr
                                                                        style={{
                                                                            verticalAlign: 'middle',
                                                                            wordWrap: 'wrap',
                                                                        }}>
                                                                        <th
                                                                            scope="col"
                                                                            colSpan={2}
                                                                            className="text-wrap responsive-xs-text"
                                                                            style={{
                                                                                paddingBottom: '1.7rem',
                                                                                borderBottom: '1px solid var(--headcount-theme-color)',
                                                                                position: 'relative',
                                                                                paddingRight: '100px',
                                                                                color: 'var(--headcount-theme-titles-color)',
                                                                                textTransform: 'uppercase'
                                                                            }}>
                                                                            {team.name}
                                                                            <span
                                                                                className="text-center responsive-xs-text"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: '42%',
                                                                                    right: '10px',
                                                                                    transform: 'translateY(-50%)',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>
                                                                                {team.total}
                                                                            </span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Array.isArray(team.data) && team.data.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="responsive-xs-text" style={{ whiteSpace: 'normal' }}>{item.FullName}</td>
                                                                            <td className="text-end responsive-xs-text">{item[team.phoneField]}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>

                                                            </Table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'organization' && (
                    <Container fluid>
                        {/* <div className="customBlockWrapper" style={orgs.length > 7 ? { height: 'auto' } : null}> */}
                        {/* <div
                            style={{
                                marginTop: orgs.length > 7
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                                marginBottom: orgs.length > 7
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                            }}> */}

                        <div
                            style={{
                                marginTop: orgs.length > 7
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "0px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                                marginBottom: orgs.length > 7
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "30px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                            }}
                        >

                            <div
                                className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                                style={{
                                    height: orgs.length > 7
                                        ? (scaleY === 1 ? "auto" : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}>

                                <div className="customBlock w-100" >
                                    <Row>
                                        <Col lg={12}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0 " style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead style={{ color: 'var(--headcount-theme-titles-color)' }}>
                                                                <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}
                                                                >
                                                                    <th scope="col" className="text-wrap text-center responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Sr. No.</th>
                                                                    <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Organization Name</th>
                                                                    {data.is_display_shift && (
                                                                        <React.Fragment>
                                                                            {data.shifts && data.shifts.map((shift, index) => (
                                                                                <React.Fragment key={index}> {/* Added key prop */}
                                                                                    <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{shift}
                                                                                    </th>
                                                                                </React.Fragment>
                                                                            ))}
                                                                            <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{data.other_shifts_display_label}
                                                                            </th>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <th scope="col" className="text-wrap text-center responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>{data.is_display_shift ? "Total Head Count" : "Head Count"}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {mainRows.length !== 0 && mainRows.map((item, index) => {
                                                                    let displayName = item.ORGName;

                                                                    return (
                                                                        <tr key={index} onClick={displayName !== "Visitors" ? handleRowClick : undefined}
                                                                            className={displayName !== "Visitors" ? 'table-row-clickable' : ''}>

                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{index + 1}</td>
                                                                            <td className="responsive-xs-text" style={{ padding: '0.6rem' }}>{displayName}</td>

                                                                            {data.is_display_shift && data.shifts !== undefined && data.shifts.map(shift => (
                                                                                <td key={shift} className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>
                                                                                    {getShiftCount(item, shift) === 0 ? "-" : getShiftCount(item, shift)}
                                                                                </td>
                                                                            ))}

                                                                            {data.is_display_shift && (
                                                                                <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>
                                                                                    {item.others_count === 0 ? "-" : item.others_count}
                                                                                </td>
                                                                            )}

                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{item.count}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                <tr key="contractors" onClick={toggleChildTable} className='responsive-xs-text' style={{ cursor: 'pointer' }}>

                                                                    <td className="text-center responsive-xs-text" style={{ padding: '0.6rem', position: 'relative' }}>
                                                                        <span
                                                                            className="text-center responsive-xs-text"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: data.is_display_shift ? '10px' : "40px",
                                                                                transform: 'translateY(-50%)',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '0px 7px',
                                                                                borderRadius: '5px'
                                                                            }}>
                                                                            <i className={showChildTable ? "ri-subtract-line" : "ri-add-line"}></i>
                                                                        </span>
                                                                        {mainRows.length + 1}</td>
                                                                    <td className={'responsive-xs-text'} style={{ padding: '0.6rem' }}>{data.contractors_group_display_label}</td>


                                                                    {data.is_display_shift && (

                                                                        <React.Fragment>
                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>
                                                                                {totalContactorCount1}
                                                                            </td>
                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>
                                                                                {totalContactorCount2}
                                                                            </td>
                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>
                                                                                {totalContactorCount3}
                                                                            </td>
                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{totalOthersCount === 0 || undefined ? "-" : totalOthersCount}</td>

                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{childRows.reduce((total, item) => total + item.count, 0)}</td>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {!data.is_display_shift && (<td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{addTotalCount(totalContractorCount, childRows)}</td>)}

                                                                </tr>
                                                                {

                                                                    showChildTable && (

                                                                        childRows.map((item, index) => {
                                                                            return (
                                                                                <tr key={`child-${index}`}>
                                                                                    <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>
                                                                                    </td>
                                                                                    <td className={'responsive-xs-text'} style={{ padding: '0.6rem' }}>{item.ORGName}</td>


                                                                                    {data.is_display_shift && data.shifts !== undefined ? (data.shifts.map(shift => (
                                                                                        <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{getShiftCount(item, shift)}</td>
                                                                                    ))) : (
                                                                                        <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}> {item.count}</td>

                                                                                    )}
                                                                                    {data.is_display_shift && (
                                                                                        <React.Fragment>
                                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{item.others_count}</td>
                                                                                            <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{item.count}</td>

                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    )
                                                                }
                                                            </tbody>
                                                            <tfoot className="table-light" style={{ fontWeight: '600' }}>
                                                                <tr>
                                                                    <td className={'responsive-xs-text'} style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }}></td>
                                                                    <td className={'responsive-xs-text'} style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} colSpan={data.is_display_shift && data.shifts !== undefined ? data.shifts.length + 2 : "1"}>Total Head Count</td>
                                                                    <td className={'responsive-xs-text text-center'} style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }}>{totalOrgHeadCount}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'category' && (
                    <Container fluid>
                        {/* <div className="customBlockWrapper" style={cats.length > 7 ? { height: 'auto' } : null}> */}

                        {/* <div
                            style={{
                                marginTop: cats.length > 7
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                                marginBottom: cats.length > 7
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                            }}> */}

                        <div
                            style={{
                                marginTop: cats.length > 7
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "0px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                                marginBottom: cats.length > 7
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "30px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                            }}
                        >
                            <div
                                className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                                style={{
                                    height: cats.length > 7
                                        ? (scaleY === 1 ? "auto" : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}>
                                <div className="customBlock w-100" >
                                    <Row>
                                        <Col lg={12}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead style={{ color: 'var(--headcount-theme-titles-color)' }}>
                                                                <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                                                                    <th scope="col" className="text-wrap text-center  responsive-xs-text" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Sr. No.</th>
                                                                    <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Categories</th>
                                                                    {data.is_display_shift && (
                                                                        <React.Fragment>
                                                                            {data.shifts && data.shifts !== undefined && data.shifts.map((shift, index) => (
                                                                                <React.Fragment key={index}> {/* Added key prop */}
                                                                                    <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{shift}
                                                                                    </th>
                                                                                </React.Fragment>
                                                                            ))}
                                                                            <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{data.other_shifts_display_label}
                                                                            </th>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <th scope="col" className="text-wrap text-center responsive-xs-text" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>{data.is_display_shift ? "Total Head Count" : "Head Count"}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {filteredCats.map((item, index) => {
                                                                    return (
                                                                        <tr key={index} onClick={item.CTGName !== "Visitors" ? handleRowClick : undefined}
                                                                            className={item.CTGName !== "Visitors" ? 'table-row-clickable' : ''}>
                                                                            <td className="text-center responsive-xs-text" style={{ padding: '1rem 0.6rem' }}>{index + 1}</td>
                                                                            <td className="responsive-xs-text" style={{ padding: '1rem 0.6rem' }}>{item.CTGName}</td>



                                                                            {data.is_display_shift && data.shifts !== undefined ? (data.shifts.map(shift => (
                                                                                <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{getShiftCount(item, shift) === 0 ? "-" : getShiftCount(item, shift)}</td>
                                                                            ))) : (
                                                                                <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{item.count === 0 ? "-" : item.count}</td>

                                                                            )}

                                                                            {data.is_display_shift && data.shifts !== undefined && (
                                                                                <React.Fragment>

                                                                                    <td className="responsive-xs-text" style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>{item.others_count}</td>
                                                                                    <td className="text-center responsive-xs-text" style={{ padding: '1rem 0.6rem' }}>{item.count}</td>
                                                                                </React.Fragment>

                                                                            )}

                                                                        </tr>
                                                                    );

                                                                })}
                                                            </tbody>
                                                            <tfoot className="table-light" style={{ fontWeight: '600' }}>
                                                                <tr>
                                                                    <td className={'responsive-xs-text'} style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }}></td>
                                                                    <td className={'responsive-xs-text'} style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} colSpan={data.is_display_shift ? data.shifts && data.shifts.length + 2 : 1}>Total Head Count</td>
                                                                    <td className={'responsive-xs-text text-center'} style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }}>{totalCatHeadCount}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'department' && (
                    <Container fluid>
                        {/* <div className="customBlockWrapper" style={depts.length > 7 ? { height: 'auto' } : null}> */}
                        {/* <div
                                style={{
                                    marginTop: depts.length > 7
                                        ? (scaleY !== 1 ? "50px" : "40px")
                                        : (scaleY !== 1 ? undefined : undefined),
                                    marginBottom: depts.length > 7
                                        ? (scaleY !== 1 ? "50px" : "40px")
                                        : (scaleY !== 1 ? undefined : undefined),
                                }}> */}

                        <div
                            style={{
                                marginTop: depts.length > 7
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "0px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                                marginBottom: depts.length > 7
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "30px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                            }}
                        >



                            <div className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                                style={{
                                    height: depts.length > 7
                                        ? (scaleY === 1 ? "auto" : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}>

                                <div className="customBlock w-100" >
                                    <Row>
                                        <Col lg={12}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead style={{ color: 'var(--headcount-theme-titles-color)' }}>
                                                                <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                                                                    <th scope="col" className="text-wrap text-center responsive-xs-text" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Sr. No.</th>
                                                                    <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>Departments</th>

                                                                    {data.is_display_shift && data.shifts && (
                                                                        <React.Fragment>
                                                                            {data.shifts && data.shifts.map((shift, index) => (
                                                                                <React.Fragment key={index}> {/* Added key prop */}
                                                                                    <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{shift}
                                                                                    </th>
                                                                                </React.Fragment>
                                                                            ))}
                                                                            <th scope="col" className="text-wrap responsive-xs-text" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>{data.other_shifts_display_label}
                                                                            </th>
                                                                        </React.Fragment>
                                                                    )}

                                                                    <th scope="col" className="text-wrap text-center responsive-xs-text" style={{ paddingBottom: '1.7rem', borderBottom: '2px solid var(--headcount-theme-color)' }}>{data.is_display_shift ? "Total Head Count" : "Head Count"}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filteredDepts.map((item, index) => {
                                                                    return (
                                                                        <tr key={index} onClick={item.DepartmentName !== "Visitors" ? handleRowClick : undefined}
                                                                            className={item.DepartmentName !== "Visitors" ? 'table-row-clickable' : ''}>
                                                                            <td className="text-center responsive-xs-text" style={{ padding: '1rem 0.6rem' }}>{index + 1}</td>
                                                                            <td className="responsive-xs-text" style={{ padding: '1rem 0.6rem' }}>{item.DepartmentName}</td>

                                                                            {data.is_display_shift && data.shifts ? (data.shifts.map(shift => (
                                                                                <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}>{getShiftCount(item, shift) === 0 ? "-" : getShiftCount(item, shift)}</td>
                                                                            ))) : (
                                                                                <td className="text-center responsive-xs-text" style={{ padding: '0.6rem' }}> {item.count === 0 ? "-" : item.count}</td>

                                                                            )}

                                                                            {data.is_display_shift && data.shifts && (
                                                                                <React.Fragment>

                                                                                    <td className="responsive-xs-text" style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>{item.others_count === 0 ? "-" : item.others_count}</td>
                                                                                    <td className="text-center responsive-xs-text" style={{ padding: '1rem 0.6rem' }}>{item.count === 0 ? "-" : item.count}</td>
                                                                                </React.Fragment>

                                                                            )}

                                                                        </tr>
                                                                    );

                                                                })}
                                                            </tbody>
                                                            <tfoot className="table-light" style={{ fontWeight: '600' }}>
                                                                <tr>
                                                                    <td className={'responsive-xs-text'} style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }}></td>
                                                                    <td className={'responsive-xs-text'} style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} colSpan={data.is_display_shift && data.shifts ? data.shifts.length + 2 : 1}>Total Head Count</td>
                                                                    <td style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white' }} className="text-center responsive-xs-text">{totalDeptHeadCount}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'headcount' && (
                    <Container fluid>

                        <div
                            style={{
                                marginTop: scaleY === 1
                                    ? "40px"
                                    : "5px",
                                marginBottom: scaleY === 1
                                    ? "40px"
                                    : "0px",
                            }}>

                            <div
                                className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                                style={{
                                    height: scaleY === 1
                                        ? "auto"
                                        : "auto"
                                }}>


                                <div className="customBlock w-100" >
                                    <Row style={{ fontSize: 'var(--table-font-size-default)' }}>
                                        <Col lg={8} style={{ margin: 'auto' }}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem', textAlign: 'center' }}>
                                                    <div style={{ fontSize: 'var(--headcount-bigcount-font-size-default)', fontWeight: 'bold', lineHeight: '1', padding: '20px 0px' }}>
                                                        {data.in_count_with_visitor}

                                                    </div>
                                                    <div style={{ fontSize: 'var(--headcount-title-font-size-default)', lineHeight: '1', backgroundColor: 'var(--headcount-theme-color)', color: 'white', padding: '15px 0', borderRadius: '2px', fontWeight: '500' }}>HEADCOUNT</div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4} style={{ marginLeft: 'auto' }}>
                                            <Card style={{ border: '2px solid #099885' }} className="table">
                                                <CardBody style={{ padding: '0', textAlign: 'center' }}>
                                                    <div className={'headcount_box'} style={{ fontSize: 'var(--headcount-smallcount-font-size-default)', lineHeight: '1' }}>
                                                        {data.hc_in_count}

                                                    </div>
                                                    <div className="alert alert-success" style={{ fontSize: 'var(--headcount-title-font-size-default)', lineHeight: '1', padding: '15px 0', borderWidth: '0px', borderTopWidth: '1px', borderRadius: '0', fontWeight: '500', margin: '0px' }}>IN</div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={4} style={{ marginRight: 'auto' }}>
                                            <Card style={{ border: '2px solid #CC563D' }} className="table">
                                                <CardBody style={{ padding: '0', textAlign: 'center' }}>
                                                    <div className={'headcount_box'} style={{ fontSize: 'var(--headcount-smallcount-font-size-default)', lineHeight: '1' }}>
                                                        {data.hc_out_count}


                                                    </div>
                                                    <div className="alert alert-danger" style={{ fontSize: 'var(--headcount-title-font-size-default)', lineHeight: '1', padding: '15px 0', borderWidth: '0px', borderTopWidth: '1px', borderRadius: '0', fontWeight: '500', margin: '0px' }}>OUT</div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'block_1' && (
                    <Container fluid>
                        {/* <div className="customBlockWrapper" style={block1TeamData.length > 6 ? { marginTop: '60px' } : { height: 'auto' }}> */}
                        {/* <div style={{height:"100vh", width: "100%",border: "2px solid black",display: "flex", alignItems: "center", justifyContent: "center"}}> */}
                        {/* <div
                            style={{
                                marginTop: shouldApplyStyleBlock1
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                                marginBottom: shouldApplyStyleBlock1
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                            }}> */}


                        <div
                            style={{
                                marginTop: shouldApplyStyleBlock1
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "0px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                                marginBottom: shouldApplyStyleBlock1
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "30px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                            }}
                        >

                            <div
                                className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                                style={{
                                    height: shouldApplyStyleBlock1
                                        ? (scaleY === 1 ? "auto" : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}>


                                <div className="customBlock w-100" >

                                    <Row>
                                        <Col xs={12}>


                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ padding: '0' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white', textTransform: 'uppercase' }}>
                                                                <tr
                                                                    style={{
                                                                        verticalAlign: 'middle',
                                                                        wordWrap: 'wrap',
                                                                        // padding:"10px 20px"
                                                                        paddingRight: '20px'
                                                                    }}
                                                                    className='tableHeadResponsive'>
                                                                    <th
                                                                        scope="col"
                                                                        className="text-center responsive-th responsive-xs-text bar-table-responsive bar-text"

                                                                        style={{

                                                                            borderBottomWidth: '0',
                                                                            paddingLeft: '15px'
                                                                        }}>
                                                                        Total Head Count
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="bar-count responsive-th responsive-xs-text bar-table-responsive"
                                                                        style={{

                                                                            borderBottomWidth: '0'
                                                                        }}>
                                                                        {data.in_count_with_visitor}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            {data.is_vms_enabled && (
                                                                <tbody style={{ color: 'var(--headcount-theme-titles-color)', fontWeight: '600' }}>
                                                                    <tr >
                                                                        <td className="text-center mob-table-body responsive-th responsive-xs-text horizontalBlueBar" style={{
                                                                            padding: '15px 15px',
                                                                            position: 'relative'
                                                                        }}>
                                                                            EMPLOYEES
                                                                            <span
                                                                                className="text-center"
                                                                                style={{
                                                                                    marginLeft: '10%',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>{data.in_count}
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-center responsive-th mob-table-body responsive-xs-text" style={{
                                                                            padding: '15px 15px',
                                                                            position: 'relative'
                                                                        }}>
                                                                            VISITORS
                                                                            <span
                                                                                className="text-center"
                                                                                style={{
                                                                                    marginLeft: '10%',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>{data.in_count_visitor}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {enabledBlock1Teams.map((team, index) => (
                                            <Col
                                                key={index}
                                                lg={12}>
                                                <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                    <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                        <div className="table-responsive">
                                                            <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                                <thead>
                                                                    <tr
                                                                        style={{
                                                                            verticalAlign: 'middle',
                                                                            wordWrap: 'wrap',
                                                                        }}>
                                                                        <th
                                                                            scope="col"
                                                                            colSpan={2}
                                                                            className="text-wrap responsive-xs-text"
                                                                            style={{
                                                                                paddingBottom: '1.7rem',
                                                                                borderBottom: '1px solid var(--headcount-theme-color)',
                                                                                position: 'relative',
                                                                                paddingRight: '100px',
                                                                                color: 'var(--headcount-theme-titles-color)',
                                                                                textTransform: 'uppercase'
                                                                            }}>
                                                                            {team.name}
                                                                            <span
                                                                                className="text-center responsive-xs-text"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: '42%',
                                                                                    right: '10px',
                                                                                    transform: 'translateY(-50%)',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>
                                                                                {team.total}
                                                                            </span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {team && Array.isArray(team.data) && team.data.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="responsive-xs-text" style={{ whiteSpace: 'normal' }}>{item.FullName}</td>
                                                                            <td className="text-end responsive-xs-text">{item[team.phoneField]}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>

                                </div>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'block_2' && (
                    <Container fluid>
                        {/* <div className="customBlockWrapper" style={block2TeamData.length > 6 ? { height: 'auto' } : null}> */}
                        {/* <div
                            style={{
                                marginTop: shouldApplyStyleBlock2
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                                marginBottom: shouldApplyStyleBlock2
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                            }}> */}

                        <div
                            style={{
                                marginTop: shouldApplyStyleBlock2
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "0px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                                marginBottom: shouldApplyStyleBlock2
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "30px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                            }}
                        >


                            <div
                                className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                                style={{
                                    height: shouldApplyStyleBlock2
                                        ? (scaleY === 1 ? "auto" : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}>


                                <div className="customBlock w-100" >
                                    <Row>
                                        <Col xs={12}>


                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ padding: '0' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white', textTransform: 'uppercase' }}>
                                                                <tr
                                                                    style={{
                                                                        verticalAlign: 'middle',
                                                                        wordWrap: 'wrap',
                                                                        paddingRight: '20px'
                                                                    }}
                                                                    className='tableHeadResponsive'>
                                                                    <th
                                                                        scope="col"
                                                                        className="text-center responsive-th responsive-xs-text bar-table-responsive bar-text"
                                                                        style={{
                                                                            paddingRight: '30',
                                                                            borderBottomWidth: '0',
                                                                            paddingLeft: '15px',
                                                                        }}>
                                                                        Total Head Count
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="bar-count responsive-th responsive-xs-text bar-table-responsive"
                                                                        style={{
                                                                            borderBottomWidth: '0'
                                                                        }}>
                                                                        {data.in_count_with_visitor}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            {data.is_vms_enabled && (
                                                                <tbody style={{ color: 'var(--headcount-theme-titles-color)', fontWeight: '600' }}>
                                                                    <tr >
                                                                        <td className="text-center mob-table-body responsive-th responsive-xs-text horizontalBlueBar" style={{
                                                                            padding: '15px 15px',
                                                                            position: 'relative'
                                                                        }}>
                                                                            EMPLOYEES
                                                                            <span
                                                                                className="text-center"
                                                                                style={{
                                                                                    marginLeft: '10%',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>{data.in_count}
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-center responsive-th mob-table-body responsive-xs-text" style={{
                                                                            padding: '15px 15px',
                                                                            position: 'relative'
                                                                        }}>
                                                                            VISITORS
                                                                            <span
                                                                                className="text-center"
                                                                                style={{
                                                                                    marginLeft: '10%',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>{data.in_count_visitor}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {enabledBlock2Teams.map((team, index) => (
                                            <Col
                                                key={index}
                                                lg={12}>
                                                <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                    <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                        <div className="table-responsive">
                                                            <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                                <thead>
                                                                    <tr
                                                                        style={{
                                                                            verticalAlign: 'middle',
                                                                            wordWrap: 'wrap',
                                                                        }}>
                                                                        <th
                                                                            scope="col"
                                                                            colSpan={2}
                                                                            className="text-wrap responsive-xs-text"
                                                                            style={{
                                                                                paddingBottom: '1.7rem',
                                                                                borderBottom: '1px solid var(--headcount-theme-color)',
                                                                                position: 'relative',
                                                                                paddingRight: '100px',
                                                                                color: 'var(--headcount-theme-titles-color)',
                                                                                textTransform: 'uppercase'
                                                                            }}>
                                                                            {team.name}
                                                                            <span
                                                                                className="text-center responsive-xs-text"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: '42%',
                                                                                    right: '10px',
                                                                                    transform: 'translateY(-50%)',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>
                                                                                {team.total}
                                                                            </span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {team && Array.isArray(team.data) && team.data.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="responsive-xs-text" style={{ whiteSpace: 'normal' }}>{item.FullName}</td>
                                                                            <td className="text-end responsive-xs-text">{item[team.phoneField]}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                )}

                {type === 'block_3' && (
                    <Container fluid>
                        {/* <div className="customBlockWrapper" style={Array.isArray(block3TeamData) && block3TeamData.length > 6 ? { height: 'auto' } : null}> */}

                        {/* <div
                            style={{
                                marginTop: shouldApplyStyleBlock3
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                                marginBottom: shouldApplyStyleBlock3
                                    ? (scaleY !== 1 ? "50px" : "40px")
                                    : (scaleY !== 1 ? undefined : undefined),
                            }}> */}

                        <div
                            style={{
                                marginTop: shouldApplyStyleBlock3
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "0px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                                marginBottom: shouldApplyStyleBlock3
                                    ? scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "50px"
                                            : "30px"
                                        : "40px"
                                    : scaleY !== 1
                                        ? viewportWidth > 1000
                                            ? "30px"
                                            : undefined
                                        : undefined,
                            }}
                        >

                            <div
                                className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                                style={{
                                    height: shouldApplyStyleBlock3
                                        ? (scaleY === 1 ? "auto" : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}>

                                <div className="customBlock w-100" >
                                    <Row>
                                        <Col xs={12}>


                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ padding: '0' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                            <thead style={{ backgroundColor: 'var(--headcount-theme-color)', color: 'white', textTransform: 'uppercase' }}>
                                                                <tr
                                                                    style={{
                                                                        verticalAlign: 'middle',
                                                                        wordWrap: 'wrap',
                                                                        paddingRight: '20px'
                                                                    }}
                                                                    className='tableHeadResponsive'>
                                                                    <th
                                                                        scope="col"
                                                                        className="text-center responsive-th responsive-xs-text bar-table-responsive bar-text"
                                                                        style={{
                                                                            // padding: '15px 25px',
                                                                            // width: '50%',
                                                                            borderBottomWidth: '0',
                                                                            paddingLeft: '15px'
                                                                        }}>
                                                                        Total Head Count
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="bar-count responsive-th responsive-xs-text bar-table-responsive"
                                                                        style={{
                                                                            // padding: '15px 25px',
                                                                            // width: '50%',
                                                                            borderBottomWidth: '0'
                                                                        }}>
                                                                        {data.in_count_with_visitor}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            {data.is_vms_enabled && (
                                                                <tbody style={{ color: 'var(--headcount-theme-titles-color)', fontWeight: '600' }}>
                                                                    <tr >
                                                                        <td className="text-center mob-table-body responsive-th responsive-xs-text horizontalBlueBar" style={{
                                                                            padding: '15px 15px',
                                                                            // borderRight: '2px solid var(--headcount-theme-color)',
                                                                            position: 'relative'
                                                                        }}>
                                                                            EMPLOYEES
                                                                            <span
                                                                                className="text-center"
                                                                                style={{
                                                                                    marginLeft: '10%',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>{data.in_count}
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-center responsive-th mob-table-body responsive-xs-text" style={{
                                                                            padding: '15px 15px',
                                                                            position: 'relative'
                                                                        }}>
                                                                            VISITORS
                                                                            <span
                                                                                className="text-center"
                                                                                style={{
                                                                                    marginLeft: '10%',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>{data.in_count_visitor}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {enabledBlock3Teams.map((team, index) => (
                                            <Col
                                                key={index}
                                                lg={12}>
                                                <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                    <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                        <div className="table-responsive">
                                                            <Table className="align-middle table-nowrap mb-0" style={{ fontSize: 'var(--table-font-size-default)' }}>
                                                                <thead>
                                                                    <tr
                                                                        style={{
                                                                            verticalAlign: 'middle',
                                                                            wordWrap: 'wrap',
                                                                        }}>
                                                                        <th
                                                                            scope="col"
                                                                            colSpan={2}
                                                                            className="text-wrap responsive-xs-text"
                                                                            style={{
                                                                                paddingBottom: '1.7rem',
                                                                                borderBottom: '1px solid var(--headcount-theme-color)',
                                                                                position: 'relative',
                                                                                paddingRight: '100px',
                                                                                color: 'var(--headcount-theme-titles-color)',
                                                                                textTransform: 'uppercase'
                                                                            }}>
                                                                            {team.name}
                                                                            <span
                                                                                className="text-center responsive-xs-text"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: '42%',
                                                                                    right: '10px',
                                                                                    transform: 'translateY(-50%)',
                                                                                    backgroundColor: 'var(--headcount-theme-color)',
                                                                                    color: 'white',
                                                                                    padding: '5px 18px',
                                                                                    borderRadius: '10px'
                                                                                }}>
                                                                                {team.total}
                                                                            </span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {team && Array.isArray(team.data) && team.data.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="responsive-xs-text" style={{ whiteSpace: 'normal' }}>{item.FullName}</td>
                                                                            <td className="text-end responsive-xs-text">{item[team.phoneField]}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                )}



                {type === 'headcountlogs' && (
                    <Container fluid>
                        {/* <div
                            style={{
                                marginTop: apiFilteredData?.length > 7
                                    ? (scaleY !== 1 ? "0px" : "0px")
                                    : (scaleY !== 1 ? undefined : undefined),
                                marginBottom: apiFilteredData?.length > 7
                                    ? (scaleY !== 1 ? "0px" : "0px")
                                    : (scaleY !== 1 ? undefined : undefined),
                            }}> */}


                        <div style={{
                            marginTop: apiFilteredData?.length > 7
                                ? scaleY !== 1
                                    ? viewportWidth > 1000
                                        ? "50px"
                                        : "0px"
                                    : "40px"
                                : scaleY !== 1
                                    ? viewportWidth > 1000
                                        ? "30px"
                                        : undefined
                                    : undefined,
                            marginBottom: apiFilteredData?.length > 7
                                ? scaleY !== 1
                                    ? viewportWidth > 1000
                                        ? "50px"
                                        : "30px"
                                    : "40px"
                                : scaleY !== 1
                                    ? viewportWidth > 1000
                                        ? "30px"
                                        : undefined
                                    : undefined,
                        }}
                        >

                            <div
                                className={viewportWidth > BASE_WIDTH ? "customBlockWrapper_hclogs" : ""}
                                style={{
                                    height: apiFilteredData?.length > 7
                                        ? (scaleY === 1 ? "auto" : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                        : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                                }}>
                                {/* <div className="customBlockWrapper" style={{ height: 'auto' }}> */}

                                <div className="customBlock w-100" >
                                    <Row>
                                        <Col sm={12} md={{ size: 6, offset: 6 }} lg={{ size: 4, offset: 8 }} className='customTextInput'>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <i className="las la-search"></i>
                                                </InputGroupText>
                                                <Input
                                                    placeholder='Search...'
                                                    value={apiSearch}
                                                    onChange={handleSearch}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table">
                                                <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-nowrap mb-0 responsive-xs-text" >
                                                            <thead style={{ color: 'var(--headcount-theme-titles-color)' }}>
                                                                <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                                                                    <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Sr.</th>
                                                                    <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Id</th>
                                                                    <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Full Name</th>
                                                                    <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Organization</th>
                                                                    <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Department</th>
                                                                    <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Designation</th>
                                                                    <th scope="col" className="text-wrap" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>In Time</th>
                                                                    <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Branch</th>
                                                                    <th scope="col" className="text-wrap text-center" style={{ paddingBottom: '1.4rem', borderBottom: '2px solid var(--headcount-theme-color)', textAlign: 'center' }}>Phone</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ paddingBottom: "50px" }}>
                                                                {apiFilteredData && Array.isArray(apiFilteredData) && apiFilteredData.length !== 0 && (
                                                                    <React.Fragment>

                                                                        {apiFilteredData && apiFilteredData.map((item, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <tr>
                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {index + 1}
                                                                                    </td>

                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {item.mx_user_id}
                                                                                    </td>

                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {item.FullName !== "" ? item.FullName : "-"}
                                                                                    </td>

                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {item.ORGName !== "" ? item.ORGName : "-"}
                                                                                    </td>

                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {item.DepartmentName !== "" ? item.DepartmentName : "-"}
                                                                                    </td>

                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {item.DesignationName !== "" ? item.DesignationName : "-"}
                                                                                    </td>

                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {item.inTime !== "" ? moment(item.in_time).format('DD-MM-YYYY hh:mm A') : "-"}
                                                                                    </td>

                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {item.BranchName !== "" ? item.BranchName : "-"}
                                                                                    </td>
                                                                                    <td style={{ padding: '1rem 0.6rem', textAlign: 'center' }}>
                                                                                        {item.PersMobile !== "" ? item.PersMobile : "-"}
                                                                                    </td>


                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}

                                                                    </React.Fragment>
                                                                )}
                                                            </tbody>

                                                        </Table>


                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>

                            </div>

                        </div>

                         <Pagination

                            data={data?.headcount_logs_count} // Pass logsData to Pagination
                            className="g-0 justify-content-end mb-4 "
                            currentPage={currentPage} // Pass currentPage to Pagination
                            setCurrentPage={setCurrentPage} // Pass setCurrentPage to Pagination
                            perPageData={perPageData} // Pass perPageData to Pagination
                        /> 

              
               
                    </Container>
                )}

                {type === 'custom_1' && (
                    <Container fluid>

                        <div
                            className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                            style={{
                                height: customBlock1TeamData.length > 6
                                    ? (scaleY === 1 ? undefined : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                    : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                            }}>

                            <div className="customBlock w-100" >
                                <Row>
                                    <Col lg={12}>
                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }}>
                                            <CardBody>
                                                <div className="table-responsive view-custom-table" style={{

                                                }}>
                                                    <th

                                                        className='responsive-xs-text'
                                                        style={{

                                                            textAlign: 'center',
                                                            color: 'white',
                                                            backgroundColor: '#d56774',
                                                            padding: '10px',
                                                            textTransform: 'uppercase',

                                                            width: "100%",
                                                            display: "block",
                                                            paddingBottom: 9,
                                                            position: "sticky",
                                                            top: 0,
                                                            zIndex: 1
                                                        }}>{customBlock1Label}</th>

                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                    }}>
                                                        {customBlock1Image !== null && (
                                                            <img src={customBlock1Image} height="40" width="40" alt="Block 1 Image" />
                                                        )}
                                                    </div>

                                                    {/* Single Table Divided into Three Columns */}
                                                    {/* <Table bordered className="table-thin">
                                                        <tbody>
                                                            {Array.from(
                                                                { length: Math.ceil(customBlock1TeamData?.length / 3) }
                                                            ).map((_, rowIndex) => {
                                                                // Sort members so `is_in` members come first
                                                                const sortedData = [...customBlock1TeamData].sort((a, b) => {
                                                                    return (b?.is_in ? 1 : 0) - (a?.is_in ? 1 : 0);
                                                                });

                                                                const member1 = sortedData[rowIndex * 3];
                                                                const member2 = sortedData[rowIndex * 3 + 1];
                                                                const member3 = sortedData[rowIndex * 3 + 2];

                                                                return (
                                                                    <tr key={rowIndex}>
                                                                      
                                                                        {member1 && (
                                                                            <>
                                                                                <td
                                                                                    className='responsive-xs-text'
                                                                                    style={{
                                                                                        padding: '5px',
                                                                                        // fontSize: 'var(--table-font-size-default)',
                                                                                        // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                                        paddingLeft: '0.6rem',
                                                                                    }}>
                                                                                    {member1?.FullName}
                                                                                </td>
                                                                                <td
                                                                                    className='responsive-xs-text'
                                                                                    style={{
                                                                                        textAlign: 'center',
                                                                                        backgroundColor: member1?.is_in ? '#1d8958' : '#d56774',
                                                                                        // fontSize: 'var(--table-font-size-default)',
                                                                                        // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                                        color: 'white',
                                                                                        padding: '5px',
                                                                                    }}>
                                                                                    {member1?.is_in ? 'In' : 'Out'}
                                                                                </td>
                                                                            </>
                                                                        )}

                                                                        {member2 && (
                                                                            <>
                                                                                <td
                                                                                    className='responsive-xs-text'
                                                                                    style={{
                                                                                        padding: '5px',
                                                                                        // fontSize: 'var(--table-font-size-default)',
                                                                                        // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                                        paddingLeft: '0.6rem',
                                                                                    }}>
                                                                                    {member2?.FullName}
                                                                                </td>
                                                                                <td className='responsive-xs-text'
                                                                                    style={{
                                                                                        textAlign: 'center',
                                                                                        backgroundColor: member2?.is_in ? '#1d8958' : '#d56774',
                                                                                        // fontSize: 'var(--table-font-size-default)',
                                                                                        // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                                        color: 'white',
                                                                                        padding: '5px',
                                                                                    }}>
                                                                                    {member2?.is_in ? 'In' : 'Out'}
                                                                                </td>
                                                                            </>
                                                                        )}

                                                                        {member3 && (
                                                                            <>
                                                                                <td
                                                                                    className='responsive-xs-text' style={{
                                                                                        padding: '5px',
                                                                                        // fontSize: 'var(--table-font-size-default)',
                                                                                        // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                                        paddingLeft: '0.6rem',
                                                                                    }}>
                                                                                    {member3?.FullName}
                                                                                </td>
                                                                                <td className='responsive-xs-text' style={{
                                                                                    textAlign: 'center',
                                                                                    backgroundColor: member3?.is_in ? '#1d8958' : '#d56774',
                                                                                    // fontSize: 'var(--table-font-size-default)',
                                                                                    // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                                    color: 'white',
                                                                                    padding: '5px',
                                                                                }}>
                                                                                    {member3?.is_in ? 'In' : 'Out'}
                                                                                </td>
                                                                            </>
                                                                        )}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table> */}

                                                    <Table bordered className="table-thin">
                                                        <tbody>
                                                            {Array.from({ length: Math.ceil(customBlock1TeamData?.length / membersPerRow) }).map((_, rowIndex) => {
                                                                const sortedData = [...customBlock1TeamData].sort((a, b) => (b?.is_in ? 1 : 0) - (a?.is_in ? 1 : 0));

                                                                const members = sortedData.slice(rowIndex * membersPerRow, (rowIndex + 1) * membersPerRow);

                                                                return (
                                                                    <tr key={rowIndex}>
                                                                        {members.map((member, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <td className="responsive-xs-text" style={{ padding: "5px", paddingLeft: "0.6rem" }}>
                                                                                    {member?.FullName}
                                                                                </td>
                                                                                <td
                                                                                    className="responsive-xs-text"
                                                                                    style={{
                                                                                        textAlign: "center",
                                                                                        backgroundColor: member?.is_in ? "#1d8958" : "#d56774",
                                                                                        color: "white",
                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    {member?.is_in ? "In" : "Out"}
                                                                                </td>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </Container>
                )}


                {type === 'custom_2' && (
                    <Container fluid>
                        <div
                            className={viewportWidth > BASE_WIDTH ? "customBlockWrapper" : ""}
                            style={{
                                height: customBlock2TeamData.length > 6
                                    ? (scaleY === 1 ? undefined : "auto")  // When shouldApplyStyle is true: "auto" only if scaleY equals 1.
                                    : (scaleY === 1 ? undefined : "auto")   // When shouldApplyStyle is false: check scaleY; if 1 then "initial", otherwise "50px".
                            }}>
                            <div className="customBlock w-100" >
                                <Row>
                                    <Col lg={12}>
                                        <Card className={'view-custom-card-body'} style={{ border: '2px solid var(--headcount-theme-color)' }}>
                                            <CardBody>
                                                <div className="table-responsive view-custom-table" style={{
                                                    // overflow: "auto",
                                                    // scrollbarWidth: "thin",
                                                    // maxHeight: "calc(100vh - 272px)"
                                                }}>
                                                    <th
                                                        className='responsive-xs-text'
                                                        style={{
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            backgroundColor: '#1d8958', // Adjusted for Fire Marshals
                                                            padding: '10px',
                                                            textTransform: 'uppercase',
                                                            // fontSize: 'var(--table-font-size-default)',
                                                            // fontSize: `calc(var(--table-font-size-default) + 7px)`,
                                                            width: "100%",
                                                            display: "block",
                                                            paddingBottom: 9,
                                                            position: "sticky",
                                                            top: 0,
                                                            zIndex: 1
                                                        }}>{customBlock2Label}</th>

                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                    }}>
                                                        {customBlock2Image !== null && (
                                                            <img src={customBlock2Image} height="40" width="40" alt="Block 2 Image" />
                                                        )}
                                                    </div>


                                                    {/* <Table bordered className="table-thin">
                                                <tbody>
                                                    
                                                    {Array.from(
                                                        { length: Math.ceil(customBlock2TeamData?.length / 3) }
                                                    ).map((_, rowIndex) => {
                                                        const sortedData = [...customBlock2TeamData].sort((a, b) => b.is_in - a.is_in);

                                                        // Fetch three members per row
                                                        const member1 = sortedData[rowIndex * 3];
                                                        const member2 = sortedData[rowIndex * 3 + 1];
                                                        const member3 = sortedData[rowIndex * 3 + 2];

                                                        return (
                                                            <tr key={rowIndex}>
                                                              
                                                                {member1 ? (
                                                                    <>
                                                                        <td
                                                                        className='responsive-xs-text'
                                                                        style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member1?.FullName}
                                                                        </td>
                                                                        <td 
                                                                        className='responsive-xs-text'
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member1?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member1?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <td colSpan="2"></td>
                                                                )}

                                                               
                                                                {member2 ? (
                                                                    <>
                                                                        <td className='responsive-xs-text' style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member2?.FullName}
                                                                        </td>
                                                                        <td 
                                                                        className='responsive-xs-text'
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member2?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member2?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <td colSpan="2"></td>
                                                                )}

                                                              
                                                                {member3 ? (
                                                                    <>
                                                                        <td 
                                                                        className='responsive-xs-text'
                                                                        style={{
                                                                            padding: '5px',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            paddingLeft: '0.6rem',
                                                                        }}>
                                                                            {member3?.FullName}
                                                                        </td>
                                                                        <td 
                                                                        className='responsive-xs-text'
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: member3?.is_in ? '#1d8958' : '#d56774',
                                                                            // fontSize: 'var(--table-font-size-default)',
                                                                            // fontSize: `calc(var(--table-font-size-default) + 4px)`,
                                                                            color: 'white',
                                                                            padding: '5px',
                                                                        }}>
                                                                            {member3?.is_in ? 'In' : 'Out'}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <td colSpan="2"></td>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table> */}

                                                    <Table bordered className="table-thin">
                                                        <tbody>
                                                            {(() => {
                                                                // Sort data once, outside the loop
                                                                const sortedData = [...customBlock2TeamData].sort((a, b) => b.is_in - a.is_in);
                                                                const itemsPerRow = membersPerRow; // Use dynamic column count

                                                                return Array.from({ length: Math.ceil(sortedData.length / itemsPerRow) }).map((_, rowIndex) => {
                                                                    // Fetch members for the current row
                                                                    const members = sortedData.slice(rowIndex * itemsPerRow, (rowIndex + 1) * itemsPerRow);

                                                                    return (
                                                                        <tr key={rowIndex}>
                                                                            {members.map((member, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    <td className="responsive-xs-text" style={{ padding: '5px', paddingLeft: '0.6rem' }}>
                                                                                        {member?.FullName}
                                                                                    </td>
                                                                                    <td className="responsive-xs-text" style={{
                                                                                        textAlign: 'center',
                                                                                        backgroundColor: member?.is_in ? '#1d8958' : '#d56774',
                                                                                        color: 'white',
                                                                                        padding: '5px',
                                                                                    }}>
                                                                                        {member?.is_in ? 'In' : 'Out'}
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            ))}
                                                                            {/* Fill empty columns if needed */}
                                                                            {Array.from({ length: itemsPerRow - members.length }).map((_, i) => (
                                                                                <td key={`empty-${i}`} colSpan="2"></td>
                                                                            ))}
                                                                        </tr>
                                                                    );
                                                                });
                                                            })()}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                )}
                {type === 'birthday_teams_2' && (
                    <Container fluid>
                        <div className="customBlockWrapper" style={{ height: 'auto' }}>
                            <div className="customBlock w-100">

                                <Row>

                                    <Col
                                        //  key={1}
                                        lg={6}>
                                        {/* <Card style={{ border: '1px solid #98a6ad' }} className="table"> */}
                                        <Card style={{ border: '1px solid #98a6ad', overflow: 'hidden', height: 'calc((100vh - 232px) / 2)' }} className="table">
                                            <CardBody style={{ paddingBottom: '1.3rem', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ fontSize: 'var(--headcount-bigcount-font-size-default)', fontWeight: 'bold', lineHeight: '1', padding: '35px 0px 0px 0px', margin: 'auto' }}>{data.in_count_with_visitor}</div>
                                                <div style={{ fontSize: 'var(--headcount-title-font-size-default)', lineHeight: '1', backgroundColor: 'var(--headcount-theme-color)', color: 'white', padding: '15px 0', borderRadius: '2px', fontWeight: '500', marginTop: 'auto' }}>HEADCOUNT</div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col lg={6}>
                                        {/* <Card style={{ border: '2px solid var(--headcount-theme-color)', overflow: 'hidden', height: 'calc((100vh - 232px) / 2)' }} className="table"> */}
                                        <Card
                                            style={{
                                                border: '2px solid var(--headcount-theme-color)',
                                                // overflow: 'hidden',
                                                // height: 'calc((100vh - 232px) / 2)',
                                            }}
                                            // className="table"
                                            className="table dynamic-height"
                                        >
                                            <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                <div
                                                    className="table-wrapper view-tables"
                                                    style={{
                                                        // maxHeight: 'calc((100vh - 300px) / 2)',
                                                        // overflow: 'auto',
                                                        // scrollbarWidth: 'thin',
                                                    }}
                                                >
                                                    <Table
                                                        className="align-middle table-wrap mb-0 table-scrollable responsive-xs-text"
                                                        style={{
                                                            // fontSize: 'var(--table-font-size-default)',
                                                            backgroundColor: 'var(--scrollable-table-header-color)',
                                                        }}
                                                    >
                                                        <thead
                                                            className="table-scrollable-header"
                                                            style={{ position: 'sticky', top: 0, zIndex: 1 }}
                                                        >
                                                            <tr
                                                                style={{
                                                                    verticalAlign: 'middle',
                                                                    wordWrap: 'wrap',
                                                                    backgroundColor: 'var(--scrollable-table-header-color)',
                                                                }}
                                                            >
                                                                <th
                                                                    scope="col"
                                                                    className="text-wrap view-tables-header"
                                                                    colSpan="2"
                                                                    style={{
                                                                        paddingBottom: '1.7rem',
                                                                        borderBottom: '1px solid var(--headcount-theme-color)',
                                                                        position: 'relative',
                                                                        // paddingRight: '100px',
                                                                        color: 'var(--headcount-theme-titles-color)',
                                                                        textTransform: 'uppercase',
                                                                    }}
                                                                >
                                                                    <div style={{ minHeight: '2rem', display: 'flex', alignItems: 'center' }}>
                                                                        {data.block_1_label}
                                                                    </div>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-wrap"
                                                                    style={{
                                                                        paddingBottom: '1.7rem',
                                                                        borderBottom: '1px solid var(--headcount-theme-color)',
                                                                        position: 'relative',
                                                                        paddingRight: '100px',
                                                                        color: 'var(--headcount-theme-titles-color)',
                                                                        textTransform: 'uppercase',
                                                                    }}
                                                                >
                                                                    <div style={{ minHeight: '2rem', display: 'flex', alignItems: 'center' }}>
                                                                        <span
                                                                            className="text-center"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '42%',
                                                                                right: '10px',
                                                                                transform: 'translateY(-50%)',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px',
                                                                            }}
                                                                        >
                                                                            {data && data.in_block_1_count}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data &&
                                                                block1TeamData !== 'undefined' &&
                                                                Array.isArray(block1TeamData) &&
                                                                block1TeamData.map((team, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ whiteSpace: 'normal' }}>{team.FullName}</td>
                                                                        <td
                                                                            className="text-start"
                                                                            style={{ color: 'var(--headcount-theme-titles-color)' }}
                                                                        >
                                                                            {team.mx_user_id}
                                                                        </td>
                                                                        <td
                                                                            className="text-start"
                                                                            style={{ color: 'var(--headcount-theme-titles-color)' }}
                                                                        >
                                                                            {team.DepartmentName}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    <Row>

                                    </Row>


                                </Row>

                                <Row>

                                    <Col
                                        // key={index}
                                        lg={6}>
                                        {/* <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table"> */}
                                        <Card style={{ border: '2px solid var(--headcount-theme-color)' }} className="table dynamic-height">
                                            <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                <div className="table-wrapper view-tables">
                                                    <Table className="align-middle table-wrap mb-0 responsive-xs-text" style={{
                                                        //  fontSize: 'var(--table-font-size-default)'
                                                    }}>
                                                        <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'var(--scrollable-table-header-color)' }}>
                                                            <tr style={{ verticalAlign: 'middle', wordWrap: 'wrap' }}>
                                                                <th
                                                                    scope="col"
                                                                    colSpan={5}
                                                                    className="text-wrap"
                                                                    style={{
                                                                        paddingBottom: '1.7rem',
                                                                        borderBottom: '1px solid var(--headcount-theme-color)',
                                                                        position: 'relative',
                                                                        paddingRight: '100px',
                                                                        color: 'var(--headcount-theme-titles-color)',
                                                                        textTransform: 'uppercase'
                                                                    }}
                                                                >
                                                                    <div style={{ minHeight: '2rem', display: 'flex', alignItems: 'center' }}>
                                                                        {data.birthday_teams_2_label}
                                                                    </div>
                                                                    <span
                                                                        className="text-center"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '42%',
                                                                            right: '10px',
                                                                            transform: 'translateY(-50%)',
                                                                            backgroundColor: 'var(--headcount-theme-color)',
                                                                            color: 'white',
                                                                            padding: '5px 18px',
                                                                            borderRadius: '10px'
                                                                        }}
                                                                    >
                                                                        {data.birthday_teams_2_count}
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.birthday_teams_2 && data?.birthday_teams_2 !== 'undefined' && data?.birthday_teams_2.map((team, index) => (
                                                                <tr key={index}>
                                                                    <td className="" style={{ width: '65%', whiteSpace: 'normal' }}>{team.FullName}</td>
                                                                    <td className="text-start" style={{ color: 'var(--headcount-theme-titles-color)' }}> {team.DepartmentName} </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>

                                    </Col>

                                    <Col
                                        key={2}
                                        lg={6}
                                    >
                                        <Card
                                            style={{
                                                border: '2px solid var(--headcount-theme-color)',
                                                // height: 'calc((100vh - 232px) / 2)',
                                            }}
                                            className="table dynamic-height"


                                        >
                                            <CardBody style={{ paddingBottom: '1.7rem' }}>
                                                <div
                                                    className="table-wrapper view-tables"
                                                    style={{
                                                        // maxHeight: 'calc((100vh - 300px) / 2)',
                                                        // overflow: 'auto',
                                                        // scrollbarWidth: 'thin',
                                                    }}
                                                >
                                                    <Table
                                                        className="align-middle table-wrap mb-0 table-scrollable responsive-xs-text"
                                                        style={{
                                                            // fontSize: 'var(--table-font-size-default)',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {/* Fixed Table Header */}
                                                        <thead
                                                            className="table-scrollable-header"
                                                            style={{
                                                                position: 'sticky',
                                                                top: 0,
                                                                zIndex: 1,
                                                                backgroundColor: 'var(--scrollable-table-header-color)',
                                                            }}
                                                        >
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    colSpan={2}
                                                                    className='text-wrap view-tables-header'
                                                                    style={{
                                                                        paddingBottom: '1.7rem',
                                                                        borderBottom: '1px solid var(--headcount-theme-color)',
                                                                        // paddingRight: '100px',
                                                                        color: 'var(--headcount-theme-titles-color)',
                                                                        textTransform: 'uppercase',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            minHeight: '2rem',
                                                                            display: 'flex',
                                                                            alignItems: 'center', // Centers content vertically
                                                                        }}
                                                                    >
                                                                        {data.block_2_label}
                                                                    </div>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    colSpan={2}
                                                                    style={{
                                                                        paddingBottom: '1.7rem',
                                                                        borderBottom: '1px solid var(--headcount-theme-color)',
                                                                        paddingRight: '100px',
                                                                        position: 'relative',
                                                                        color: 'var(--headcount-theme-titles-color)',
                                                                        textTransform: 'uppercase',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            minHeight: '2rem',
                                                                            display: 'flex',
                                                                            alignItems: 'center', // Centers content vertically
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '42%',
                                                                                right: '10px',
                                                                                transform: 'translateY(-50%)',
                                                                                backgroundColor: 'var(--headcount-theme-color)',
                                                                                color: 'white',
                                                                                padding: '5px 18px',
                                                                                borderRadius: '10px',
                                                                            }}
                                                                        >
                                                                            {data?.in_block_2_count}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        {/* Scrollable Table Body */}
                                                        <tbody>
                                                            {data &&
                                                                block2TeamData !== 'undefined' &&
                                                                Array.isArray(block2TeamData) &&
                                                                block2TeamData?.map((team, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ whiteSpace: 'normal' }}>{team.FullName}</td>
                                                                        <td
                                                                            className="text-start"
                                                                            style={{ color: 'var(--headcount-theme-titles-color)' }}
                                                                        >
                                                                            {team.mx_user_id}
                                                                        </td>
                                                                        <td
                                                                            className="text-start"
                                                                            style={{ color: 'var(--headcount-theme-titles-color)' }}
                                                                        >
                                                                            {team.DepartmentName}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>

                                    </Col>


                                </Row>

                            </div>
                        </div>
                    </Container>
                )}



            </div>
        </React.Fragment >
    );
};

export default ViewDetail;