import axios from "axios";
import { HC_API } from "../config";


axios.defaults.headers.common["Authorization"] = `Bearer ${HC_API.BEARER_TOKEN}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

// Retry counter, can be placed globally if needed
var retryCount = 0;
const MAX_RETRIES = 3; // Maximum retry attempts

const fetchCompanyDetails = async () => {
  try {
    const url = `${HC_API.API_URL}/api/hc_templates/company-details`;
    const response = await axios.get(url);
    // console.log("Company Details API Response : ", response);

    if (response && response.success) {
      if (response.data) {
        const companyData = {
          logo: response.data.logo,
          logo_height: response.data.logo_height,
          logo_dark: response.data.logo_dark,
          name: response.data.name,
          color: response.data.color
        };
        // console.log("Company Data : ",companyData);
        return companyData;
      } else {
        // console.log("Response Data is Empty");
      }
    } else {
      console.log("Error: Response is not successful");
      return []; // Return null or handle error case as per your application's logic
    }
  } catch (error) {
    console.log("Error fetching company details:", error);
    return []; // Return null or handle error case as per your application's logic
  }
};

const fetchHCTemplates = async () => {
  try {
    const url = `${HC_API.API_URL}/api/hc_templates/list`;
    const response = await axios.get(url);
    // console.log("Template List API Response : ", response);

    if (response && response.success) {
      if (response.data.length > 0) {
        const templates = response.data.map(template => ({
          id: template.id,
          name: template.name,
          hc_template_type: template.hc_template_type,
          active: template.active
        }));
        // console.log("Template Data : ",templates);
        return templates;
      } else {
        console.log("Response Data is Empty");
        return [];
      }
    } else {
      // console.log("Response is not successful", response);
      return [];
    }
  } catch (error) {
    console.log("Error in firing HC Templates API: ", error);
    return [];
  }
};

const fetchHCTemplateDetail = async (id) => {
  try {

    // console.log("id inside api : ",id);

    const data = new URLSearchParams();
    data.append('template_id', id);

    const url = `${HC_API.API_URL}/api/hc_templates/detail`;
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (response && response.success) {

      return {
        viewTypes: response.data.view_types,
        template: response.data.template
      };

    } else {
      console.log("Response is not successful", response);
      return [];
    }
  } catch (error) {
    console.log("Error in firing HC Template Detail API: ", error);
    return [];
  }
};

const fetchHCAll = async (templateId, viewType, hardRefresh) => {
  try {

    console.log("View Type for fetchHCAll is ", viewType)
    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    let in_count_with_visitor = response.data?.in_count_with_visitor
    // let in_count_with_visitor = 0;
    let dataAppended = false

    console.log("Initial Value of inCOunt Visitor", in_count_with_visitor)
    if (response && response.success) {

      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }

      retryCount = 0;
      dataAppended = true;

      let responseData = response.data;
      let block1TeamData = response.data.block_1_team;
      let block2TeamData = response.data.block_2_team;
      let block3TeamData = response.data.block_3_team;

      let is_block_1_team_enabled = response.data.is_block_1_team_enabled;
      let is_block_2_team_enabled = response.data.is_block_2_team_enabled;
      let is_block_3_team_enabled = response.data.is_block_3_team_enabled;

      let trueCount =
        +is_block_1_team_enabled +
        +is_block_2_team_enabled +
        +is_block_3_team_enabled;

      return {
        data: responseData,
        block1TeamData: block1TeamData,
        block2TeamData: block2TeamData,
        block3TeamData: block3TeamData,
        is_block_1_team_enabled: is_block_1_team_enabled,
        is_block_2_team_enabled: is_block_2_team_enabled,
        is_block_3_team_enabled: is_block_3_team_enabled,
        trueCount: trueCount,
        dataAppended: dataAppended
      };

    } else {
      console.log("Response is not successful", response);
      return {
        data: [],
        block1TeamData: [],
        block2TeamData: [],
        block3TeamData: []
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      data: []
    };
  }
};

const fetchHCORGHeadCountData = async (templateId, viewType, hardRefresh) => {
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;


    console.log("URL Generated ",url)
    
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    console.log("RESPONSE from ORGHeadcount URL", response)
    let in_count_with_visitor = response.data.in_count_with_visitor;
    // let in_count_with_visitor = 0;
    let dataAppended = false
    
    if (response && response.success) {
      
      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }

      retryCount = 0;
      dataAppended = true;

      let data = response.data;
      let orgs = response.data.orgs;
      console.log("Data Appended Value is True", dataAppended)


      return {
        data: data,
        orgs: orgs,
        totalOrgHeadCount: in_count_with_visitor,
        dataAppended: dataAppended,
      };

    } else {
      console.log("Response is not successful", response);
      return {
        orgs: [],
        data: [],
        totalOrgHeadCount: ""
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      orgs: [],
      data: [],
      totalOrgHeadCount: ""
    };
  }
};

const fetchHCCATHeadCountData = async (templateId, viewType, hardRefresh) => {
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;

    console.log("URL Generated ",url)
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    // console.log("Head Count Data API Response : ", response);

    let in_count_with_visitor = response.data.in_count_with_visitor;
    // let in_count_with_visitor = 0;
    let dataAppended = false;


    if (response && response.success) {

      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }
      retryCount = 0;
      let data = response.data;
      dataAppended = true;
      let cats = response.data.ctgs;



    
      // console.log('response data : ',data);
      return {
        data: data,
        cats: cats,
        totalCatHeadCount: in_count_with_visitor,
        dataAppended: dataAppended,
      };

    } else {
      console.log("Response is not successful", response);
      return {
        cats: [],
        totalCatHeadCount: ""
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      cats: [],
      totalCatHeadCount: ""
    };
  }
};

const fetchHCDeptHeadCountData = async (templateId, viewType, hardRefresh) => {
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    let in_count_with_visitor = response.data.in_count_with_visitor;
    // let in_count_with_visitor = 0;
    let dataAppended = false
    // console.log("Head Count Data API Response : ", response);

    if (response && response.success) {


      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }

      retryCount = 0;
      dataAppended = true;
      let data = response.data;
      let depts = response.data.departments;

      console.log('response data : ', data);
      return {
        data: data,
        depts: depts,
        totalDeptHeadCount: in_count_with_visitor,
        dataAppended: dataAppended,
      };

    } else {
      console.log("Response is not successful", response);
      return {
        cats: [],
        totalCatHeadCount: ""
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      cats: [],
      totalCatHeadCount: ""
    };
  }
};

const fetchHCHeadCountData = async (templateId, viewType, hardRefresh) => {
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

 let in_count_with_visitor = response.data.in_count_with_visitor;
    // let in_count_with_visitor = 0;

    
    let dataAppended = false;

    
    if (response && response.success) {

      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }

      let data = response.data;
      retryCount = 0;
      dataAppended = true;

      console.log('response data : ', data);
      return {
        data: data,
        dataAppended: dataAppended,
        in_count_with_visitor: in_count_with_visitor
      };

    } else {
      console.log("Response is not successful", response);
      return {
        cats: [],
        totalCatHeadCount: ""
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      cats: [],
      totalCatHeadCount: ""
    };
  }
};

const fetchHCBlock1 = async (templateId, viewType, hardRefresh) => {
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    let in_count_with_visitor = response.data.in_count_with_visitor;
    // let in_count_with_visitor = 0;
    let dataAppended = false

    // console.log("HC All Data API Response : ", response);

    if (response && response.success) {

      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }

      dataAppended = true;
      retryCount = 0;

      let responseData = response.data;
      let block1TeamData = response.data.block_1_team;
      let is_block_1_team_enabled = response.data.is_block_1_team_enabled;
      let trueCount = is_block_1_team_enabled

      return {
        data: responseData,
        block1TeamData: block1TeamData,
        is_block_1_team_enabled: is_block_1_team_enabled,
        trueCount: trueCount,
        dataAppended: dataAppended
      };

    } else {
      console.log("Response is not successful", response);
      return {
        data: [],
        block1TeamData: [],
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      data: []
    };
  }
};

const fetchHCBlock2 = async (templateId, viewType, hardRefresh) => {
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    // console.log("HC All Data API Response : ", response);
    // let in_count_with_visitor = 0;
     let in_count_with_visitor = response.data.in_count_with_visitor;
    
    let dataAppended = false
    if (response && response.success) {


      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }

      dataAppended = true;
      retryCount = 0;

      let responseData = response.data;
      let block2TeamData = response.data.block_2_team;
      let is_block_2_team_enabled = response.data.is_block_2_team_enabled;
      let trueCount = is_block_2_team_enabled

      return {
        data: responseData,
        block2TeamData: block2TeamData,
        is_block_2_team_enabled: is_block_2_team_enabled,
        trueCount: trueCount,
        dataAppended: dataAppended
      };

    } else {
      console.log("Response is not successful", response);
      return {
        data: [],
        block2TeamData: [],
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      data: []
    };
  }
};

const fetchHCBlock3 = async (templateId, viewType, hardRefresh) => {
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    console.log("Inside FetchHCBlock3 ", viewType)

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    // let in_count_with_visitor = 0;
    let in_count_with_visitor = response.data.in_count_with_visitor;
    let dataAppended = false
    // console.log("HC All Data API Response : ", response);
    if (response && response.success) {



      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }

      retryCount = 0;
      dataAppended = true;


      console.log("Got Success Response in HC Block 3")

      let responseData = response.data;
      let block3TeamData = response.data.block_3_team;
      let is_block_3_team_enabled = response.data.is_block_3_team_enabled;
      let trueCount = is_block_3_team_enabled
      
      return {
        data: responseData,
        block3TeamData: block3TeamData,
        is_block_3_team_enabled: is_block_3_team_enabled,
        trueCount: trueCount,
        dataAppended: dataAppended,
      };

    } else {
      console.log("Response is not successful", response);
      return {
        data: [],
        block1TeamData: [],
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      data: []
    };
  }
};


const fetchHCLogs = async (templateId, viewType, hardRefresh, perPageData,indexOfFirst, apiSearch) => {

  // console.log("ID TYPE 0 PPD IOF DATA from APIS PAGE", id, type,0, perPageData, indexOfFirst)
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);
    data.append('search', apiSearch)
    
    if (perPageData === null || perPageData === undefined) {
      perPageData = 10;
    }
   
    data.append('length', perPageData)
    data.append('start', indexOfFirst)

    const url = `${HC_API.API_URL}/api/hc_templates/headcount-logs`;
    const testingUrl = `http://192.168.29.80:8001/api/hc_templates/headcount-logs`
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });


    let logsData = []

    if (response && response.success) {

      let responseData = response.data;
      logsData = response.data.headcount_logs;
      if(Array.isArray(logsData) && logsData.length !== 0){
        // setHCLogsApiCheckCall(true)
      } 
      else if(logsData.length === 0){
        // console.log("Getting An empty array")

        
       
        
      }
      else{
        // console.log("Some other issue")
        logsData = response.data.headcount_logs;
      }

      console.log("Response Data of Headcount Logs", logsData)

      return {
        data: responseData,
        response: response,
        logsData: logsData,
      };

    } else {
      console.log("Response is not successful", response);
      return {
        data: [],
        logsData: [],
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Logs API: ", error);
    return {
      data: []
    };
  }
};




const fetchCustomBlock = async (templateId, viewType, hardRefresh) => {
  try {

    const data = new URLSearchParams();
    data.append('template_id', templateId);
    data.append('type', viewType);
    data.append('hard_refresh', hardRefresh);

    const url = `${HC_API.API_URL}/api/hc_templates/detail-data`;
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    let in_count_with_visitor = response.data.in_count_with_visitor;
    // let in_count_with_visitor = 0; 
    let dataAppended = false


    if (response && response.success) {

      if (in_count_with_visitor == 0 || in_count_with_visitor == null || in_count_with_visitor == undefined || in_count_with_visitor == "") {
        console.log("In count with visitors is 0")
        retryCount += 1;
        if (retryCount < MAX_RETRIES) {
          console.log("retry count is less than 3", retryCount)
          dataAppended = false;
          return 0;
        } 
      }

      retryCount = 0;
      dataAppended = true;
      let data = response.data;

      console.log('response data from posts: ', response);
      return {
        data: data,
        dataAppended: dataAppended
      };

    } else {
      console.log("Response is not successful", response);
      return {
        data: [],
        
      };
    }
  } catch (error) {
    console.log("Error in firing Head Count Data API: ", error);
    return {
      data: [],
     

    };
  }
};

export {
  fetchHCTemplates,
  fetchCompanyDetails,
  fetchHCTemplateDetail,
  fetchHCAll,
  fetchHCORGHeadCountData,
  fetchHCCATHeadCountData,
  fetchHCDeptHeadCountData,
  fetchHCHeadCountData,
  fetchHCBlock1,
  fetchHCBlock2,
  fetchHCBlock3,
  fetchHCLogs,
  fetchCustomBlock
};
