import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useLocation, useParams } from 'react-router-dom';
import { ViewDetailContext } from '../App';

import '../assets/css/hc_custom.css';



const Footer = () => {

    const location = useLocation();
    const isViewDetailPage = location.pathname.includes("view-detail");
    const { data, templateDetail, lastApiResponseTime } = useContext(ViewDetailContext);
    const viewTypes = templateDetail.viewTypes || {};
    const { type } = useParams();
    
    const currentViewType = viewTypes.find(viewType => viewType.type === type);
    const currentViewTypeName = currentViewType ? currentViewType.name : '';
//     const displayText = data.template && data.template.name
//   ? `${data.template.name} - Logs`
//   : (data && data.template_name ? `${data.template_name} - ${currentViewTypeName}` : `Default Text - ${currentViewTypeName}`);

    
    const formattedTime = lastApiResponseTime ?
    new Date(lastApiResponseTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }) : 'N/A';
    // const templateName =  data.template && data.template.name ? data.template.name : null || null;


    return (
        <React.Fragment>
            <footer className="footer text-center"
                    style={{
                        padding: '0px calc(1.5rem * 0.5)',
                        display: 'flex',
                        borderTop: '1px solid var(--ck-accessibility-help-dialog-border-color)'}}>
                <Container fluid>
                    <Row style={{height:'100%'}}>
                        <Col xs={12} sm={4} md={4} lg={4} style={{color: 'var(--headcount-theme-titles-color)', fontWeight:'600', display: 'flex'}}>

                            {isViewDetailPage && data && type !== "headcountlogs" &&  (
                                  <div className="footerLeft"> 
                              
                                                              
                                    {data.length !== 0 && data.template && data.template.name ? `${data.template.name} - Logs`:`${data.template_name} - ${currentViewTypeName}`}
                                </div>
                            )}
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4} style={{display:'flex'}}>
                            <div className={'footerMiddle'} style={{margin:'auto'}}>
                            Copyright © {new Date().getFullYear()} <div style={{display: "inline"}}>
                                
                                <a href="https://blueerasoftech.com" target="_blank"><strong>BlueEra Softech</strong></a> ®
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4} style={{color: 'var(--headcount-theme-titles-color)', fontWeight:'600', display: 'flex'}}>
                            {isViewDetailPage && data && type !== "headcountlogs" && (
                                // <div style={{margin:'auto',marginRight:'0px'}}>
                                <div className='footerRight'>
                                    <i className="bx bx-refresh footer-refresh-icon" style={{position:'absolute',marginLeft: '-45px'}}></i> <span className='footerRightText'>{formattedTime}</span>
                                </div>
                            )}
                        </Col>

                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
