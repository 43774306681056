// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Row } from "reactstrap";

// const Pagination = ({ data, currentPage, setCurrentPage, perPageData }) => {

//     const handleClick = (e) => {
//         setCurrentPage(e);
//     };

//     const pageNumbers = [];

//     if(Array.isArray(data)){

//         for (let i = 1; i <= Math.ceil(data?.length / perPageData); i++) {
//             pageNumbers.push(i);
//         }
//     }
//     else{

//         for (let i = 1; i <= Math.ceil(data / perPageData); i++) {
//             pageNumbers.push(i);
//         }

//     }
//     const handleprevPage = () => {
//         let prevPage = currentPage - 1;
//         setCurrentPage(prevPage);
//     };
//     const handlenextPage = () => {
//         let nextPage = currentPage + 1;
//         setCurrentPage(nextPage);
//     };

//     useEffect(() => {
//         if (pageNumbers.length && pageNumbers.length < currentPage) {
//             setCurrentPage(pageNumbers.length)
//         }
//     }, [pageNumbers.length, currentPage, setCurrentPage])

//     useEffect(()=>{
//         // console.log("Current Data in Pagination Array", pageNumbers)
//     },[pageNumbers])
//     return (
//         <React.Fragment>
//             <Row className="g-0 justify-content-end mb-4">
//                 <div className="col-sm-auto">
//                     <ul className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
//                         {currentPage <= 1 ? (
//                             <Link className="page-item pagination-prev disabled" href="#!">
//                                 Previous
//                             </Link>
//                         ) :
//                             <li className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
//                                 <Link to="#!" className="page-link" onClick={handleprevPage}>Previous</Link>
//                             </li>
//                         }
//                         {pageNumbers.map((item, key) => (
//                             <React.Fragment key={key}>
//                                 <li className="page-item">
//                                     <Link to="#!" className={currentPage === item ? "page-link active" : "page-link"} onClick={() => handleClick(item)}>{item}</Link>
//                                 </li>
//                             </React.Fragment>
//                         ))}
//                         {currentPage >= pageNumbers.length ? (
//                             <Link className="page-item pagination-next disabled" href="#!">
//                                 Next
//                             </Link>
//                         ) :
//                             <li className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
//                                 <Link to="#!" className="page-link" onClick={handlenextPage}>Next</Link>
//                             </li>
//                         }
//                     </ul>
//                 </div>
//             </Row>
//         </React.Fragment>
//     );
// }

// export default Pagination;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";

const Pagination = ({ data, currentPage, setCurrentPage, perPageData }) => {
  // Calculate total pages from data (works whether data is an array or a count)
  const totalPages = Array.isArray(data)
    ? Math.ceil(data.length / perPageData)
    : Math.ceil(data / perPageData);

  // Returns an array of pages and ellipsis (as strings) for pagination display
  const getPageNumbers = () => {
    // If total pages are small, no need for ellipsis.
    if (totalPages <= 10) {
      return [...Array(totalPages)].map((_, idx) => idx + 1);
    }

    const pages = [];

    // Always show the first 5 pages
    pages.push(1, 2, 3,4 , 5);

    let leftEllipsis = false;
    let rightEllipsis = false;
    let middlePages = [];

    // Determine what to show in the middle.
    // Case 1: Current page is in the middle (not too near the beginning or end)
    if (currentPage > 4 && currentPage < totalPages - 3) {
      leftEllipsis = true;
      rightEllipsis = true;
      middlePages = [currentPage - 1, currentPage, currentPage + 1];
    }
    // Case 2: Current page is near the beginning
    else if (currentPage <= 4) {
      // Extend the range after the first 3 pages a bit
      middlePages = [4, 5];
      rightEllipsis = true;
    }
    // Case 3: Current page is near the end
    else {
      leftEllipsis = true;
      middlePages = [totalPages - 4, totalPages - 3];
    }

    // Add left ellipsis if needed
    if (leftEllipsis) {
      pages.push("...");
    }
    // Append middle pages ensuring no duplicates
    pages.push(...middlePages.filter(page => page > pages[pages.length - 1]));
    // Add right ellipsis if needed
    if (rightEllipsis) {
      pages.push("...");
    }
    // Always show the last 3 pages
    pages.push(totalPages - 2, totalPages - 1, totalPages);

    // Remove duplicates and filter out any pages that are out of range
    const uniquePages = [];
    pages.forEach(item => {
      if (uniquePages.indexOf(item) === -1 && (typeof item === "string" || (item >= 1 && item <= totalPages))) {
        uniquePages.push(item);
      }
    });
    return uniquePages;
  };

  const pageNumbers = getPageNumbers();

  // Handlers to change the page
  const handleClick = (page) => {
    if (page !== "..." && page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  // If current page is out of range (say total pages drop), adjust it.
  useEffect(() => {
    if (totalPages && currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage, setCurrentPage]);

  return (
    <React.Fragment>
        
      <Row className="g-0 justify-content-end mb-4">
        <div className="col-sm-auto">
        <ul className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0 flex-wrap">

          {/* <ul className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0"> */}
            {currentPage <= 1 ? (
              <span className="page-item pagination-prev disabled" style={{marginTop: 5,marginBottom: 5}}>Previous</span>
            ) : (
              <li className="page-item">
                <Link to="#!" className="page-link" style={{marginTop: 5,marginBottom: 5}} onClick={handlePrevPage}>
                  Previous
                </Link>
              </li>
            )}
            {pageNumbers.map((item, index) => (
              <li key={index} className="page-item">
                {item === "..." ? (
                  <span style={{marginTop: 5,marginBottom: 5}} className="page-link">...</span>
                ) : (
                  <Link
                    to="#!"
                    style={{marginTop: 5,marginBottom: 5}}
                    className={`page-link ${currentPage === item ? "active" : ""}`}
                    onClick={() => handleClick(item)}
                  >
                    {item}
                  </Link>
                )}
              </li>
            ))}
            {currentPage >= totalPages ? (
              <span style={{marginTop: 5,marginBottom: 5}} className="page-item pagination-next disabled">Next</span>
            ) : (
              <li style={{marginTop: 5,marginBottom: 5}} className="page-item">
                <Link to="#!" className="page-link"  onClick={handleNextPage}>
                  Next
                </Link>
              </li>
            )}
          </ul>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default Pagination;
