import React, { useState, useEffect } from 'react';
import './assets/scss/themes.scss';
import Route from './Routes';
import fakeBackend from "./helpers/AuthType/fakeBackend";

fakeBackend();


export const ViewDetailContext = React.createContext();

function App() {
    const [templateDetail, setTemplateDetail] = useState({
        viewTypes: [],
        template: {}
    });
    const [lastApiResponseTime, setLastApiResponseTime] = useState(null);

    const BASE_WIDTH = 1440;
    const BASE_HEIGHT = 900;

    

console.log("Screen:", window.screen.width, window.screen.height);
console.log("Viewport:", window.innerWidth, window.innerHeight);
console.log("Device Pixel Ratio:", window.devicePixelRatio);

console.log("Screen width:", window.screen.width);
console.log("Screen height:", window.screen.height);


    const [data, setData] = useState([]);
    const [block1TeamData, setBlock1TeamData] = useState([]);
    const [block2TeamData, setBlock2TeamData] = useState([]);
    const [block3TeamData, setBlock3TeamData] = useState([]);

    const [headcountIn,setHeadcountIn] = useState([]);
    const [headcountOut, setHeadcountOut] = useState([])

    const [isBlock1TeamEnabled, setIsBlock1TeamEnabled] = useState(false);
    const [isBlock2TeamEnabled, setIsBlock2TeamEnabled] = useState(false);
    const [isBlock3TeamEnabled, setIsBlock3TeamEnabled] = useState(false);
    const [trueCount, setTrueCount] = useState(0);

    const [rawShiftNames, setRawShiftNames] = useState([])
    const [shiftCounts, setShiftCounts] = useState([]);

    const [customBlock1Label, setCustomBlock1Label] = useState('')
    const [customBlock2Label, setCustomBlock2Label] = useState('')
    const [customBlock1InCount, setCustomBlock1InCount] = useState('')
    const [customBlock2InCount, setCustomBlock2InCount] = useState('')

    const [customBlock1TeamData, setCustomBlock1TeamData] = useState('')
    const [customBlock2TeamData, setCustomBlock2TeamData] = useState('')

    const [customBlock1Image,setCustomBlock1Image] = useState(null)
    const [customBlock2Image,setCustomBlock2Image] = useState(null)

    const [orgs, setOrgs] = useState([]);
    const [totalOrgHeadCount, setTotalOrgHeadCount] = useState('');
    const [expandedRows, setExpandedRows] = useState([]);
    const [showChildTable, setShowChildTable] = useState(false);
    const [inCountWithVisitor,setIn_count_with_visitor] = useState(0);

    


    const [cats, setCats] = useState([]);
    const [totalCatHeadCount, setTotalCatHeadCount] = useState('');

    const [depts, setDepts] = useState([]);
    const [totalDeptHeadCount, setTotalDeptHeadCount] = useState('');

    const [ertMembersPresent, setErtMembersPresent] = useState([]);
    const [totalErtMembersAvailableState, setTotalErtMembersAvailable] = useState('');

    const [logsData, setLogsData] = useState([])
    const [HCLogsData, setHCLogsData] = useState([])



    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [aspectRatio, setAspectRatio] = useState((window.innerWidth / window.innerHeight).toFixed(2));
    const [scale, setScale] = useState(1);
   
    const updateScale = () => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        console.log("Viewport:", viewportWidth, viewportHeight);
    
        if (viewportWidth < BASE_WIDTH && viewportHeight < BASE_HEIGHT) {
          const scaleX = viewportWidth / BASE_WIDTH;
          const scaleY = viewportHeight / BASE_HEIGHT;
          const computedScale = Math.min(scaleX, scaleY);
          // Optional: dampening so scaling isn’t too aggressive
          const dampeningFactor = 0.5; // tweak this value (0 to 1)
          const newScale = 1 - (1 - computedScale) * dampeningFactor;
          console.log("Scaling down. Computed scale:", computedScale, "New dampened scale:", newScale);
          setScale(newScale);
        } else {
          console.log("No scaling. Using scale: 1");
          setScale(1);
        }
      };
    
      useEffect(() => {
        updateScale();
        window.addEventListener('resize', updateScale);
        return () => window.removeEventListener('resize', updateScale);
      }, []);

    const containerStyle = {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        // width: `${BASE_WIDTH}px`,
        // height: `${BASE_HEIGHT}px`,
        overflow: "hidden",
    };

    return (
        <React.Fragment>
            {/* <div style={containerStyle}> */}

            
            <ViewDetailContext.Provider
                value={{
                    templateDetail, setTemplateDetail,

                    lastApiResponseTime, setLastApiResponseTime,

                    data, setData,
                    block1TeamData, setBlock1TeamData,
                    block2TeamData, setBlock2TeamData,
                    block3TeamData, setBlock3TeamData,
                    isBlock1TeamEnabled, setIsBlock1TeamEnabled,
                    isBlock2TeamEnabled, setIsBlock2TeamEnabled,
                    isBlock3TeamEnabled, setIsBlock3TeamEnabled,
                    trueCount, setTrueCount,
                    inCountWithVisitor,setIn_count_with_visitor,
                    headcountIn,setHeadcountIn,
                    headcountOut,setHeadcountOut,
                    orgs, setOrgs,
                    totalOrgHeadCount, setTotalOrgHeadCount,
                    expandedRows, setExpandedRows,
                    showChildTable, setShowChildTable,

                    cats, setCats,
                    totalCatHeadCount, setTotalCatHeadCount,

                    depts, setDepts,
                    totalDeptHeadCount, setTotalDeptHeadCount,
                    rawShiftNames, setRawShiftNames,
                    shiftCounts, setShiftCounts,
                    logsData, setLogsData,
                    HCLogsData, setHCLogsData,

                    customBlock1Label, setCustomBlock1Label,
                    customBlock2Label, setCustomBlock2Label,
                    customBlock1InCount,setCustomBlock1InCount,
                    customBlock2InCount,setCustomBlock2InCount,
                    customBlock1TeamData,setCustomBlock1TeamData,
                    customBlock2TeamData,setCustomBlock2TeamData,
                    customBlock1Image,setCustomBlock1Image,
                    customBlock2Image,setCustomBlock2Image


                }}
            >
                <Route />
            </ViewDetailContext.Provider>
            {/* </div> */}
        </React.Fragment>
    );
}

export default App;
